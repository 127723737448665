import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/Account/auth.service';

@Component({
  selector: 'app-auth-cover-login',
  templateUrl: './auth-cover-login.component.html',
  styleUrls: ['./auth-cover-login.component.css']
})

/**
 * Auth Cover-Login Component
 */
export class AuthCoverLoginComponent implements OnInit {
  signinForm: FormGroup;

  constructor(public authService: AuthService,
              public fb: FormBuilder,
              public router: Router) {
    this.signinForm = this.fb.group({
      userName: [''],
      password: [''],
    });
   }

  ngOnInit(): void {
  }

  loginUser() {
    this.authService.signIn(this.signinForm.value);
  }

}
