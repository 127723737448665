<section>
  <div class="container ">
    <div class="row d-flex mt-100" >
      <div class="col pt-4">
        <h1 class="text-center">{{communicat}}</h1>
        <p class="text-center">{{communicat2}}</p>
        <h3 class="text-center mb-100">Numer: {{numberRezwer}} </h3>
      </div>
    </div>
  </div>
  <div class="mt-100">

  </div>
</section>
<!-- Event snippet for Dokonanie Rezerwacji conversion page -->
<!-- <script>
  gtag('event', 'conversion', {
      'send_to': 'AW-11220153175/qiG5CIK9vLkYENfml-Yp',
      'transaction_id': ''
  });
</script> -->

