import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CheckPriceModel } from 'src/app/core/Model/CheckPriceModel/CheckPriceModel';
import { PriceModel } from 'src/app/core/Model/CheckPriceModel/PriceModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckPriceService {

  constructor(private http: HttpClient) {}

  conetingString:string = environment.api_url + "Form/ChceckPrice";

  private price = new Subject<number>();

  CheckPriceModel:CheckPriceModel = {
    offertGuid: '',
    checkIn: '',
    checkOut: '',
    hourIn: "11:00",
    hourOut: '12:00',
    cars: 1,
    discountCupon: ''
  };

  CheckIn:Date= new Date()
  CheckOut:Date = new Date()

  UpdateCheckIn(date:Date){
    this.CheckIn=date;
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    this.CheckPriceModel.checkIn=`${year}-${month}-${day}`;
  }
  UpdateHourIn(hour:string){
    this.CheckPriceModel.hourIn=hour
  }
  UpdateCheckOut(date:Date){
    this.CheckOut=date
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    this.CheckPriceModel.checkOut=`${year}-${month}-${day}`;
  }
  UpdateHourOut(hour:string){
    this.CheckPriceModel.hourOut=hour
  }

  UpdateCars(cars:number){
    this.CheckPriceModel.cars=cars
  }

  UpdateDiscountCupon(cupon : string){
    this.CheckPriceModel.discountCupon=cupon
  }

  UpdateOffertGuid(offertGuid:string){
    this.CheckPriceModel.offertGuid = offertGuid
  }

  getPrice() : Observable<PriceModel> {
    if(this.CheckPriceModel.offertGuid!='' && this.CheckPriceModel.checkIn!='' && this.CheckPriceModel.checkOut!=''){
      if(this.CheckIn<=this.CheckOut){

        return this.http.post<PriceModel>(this.conetingString,this.CheckPriceModel)
      }
      throw new Error('Incorrectly stated date')
    }
    throw new Error("There are not all the variables");

  }
}
