<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title">Polityka Prywatności</h4>
          <ul class="list-unstyled mt-4">
            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Ostatnia Aktualizacja :</span> 10 Maj 2023</li>
          </ul>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="card shadow rounded border-0">
          <div class="card-body">


            <h5>§1 Postanowienia ogólne</h5>
            <p>
              Administratorem danych jes Kamil Cichoń, prowadzący działalność gospodarczą pod firmą SOFTWARE Kamil Cichoń z siedzibą ul. Dworcowa 8a, 42-625 Ożarowice. wpisany do Centralnej Ewidencji i Informacji o Działalności Gospodarczej, prowadzonej przez ministra właściwego ds. rozwoju, REGON: 522138250 NIP: 6452575239
            </p>
            <ol>
            <li>
              Ten dokument stanowi załącznik do Regulaminu. Korzystając z naszych usług, powierzasz nam
              swoje informacje. Niniejsza Polityka prywatności służy jedynie jako pomoc w zrozumieniu, jakie
              informacje i dane są zbierane i w jakim celu oraz do czego je wykorzystujemy. Te dane są bardzo dla
              nas ważne, dlatego prosimy o dokładne zapoznanie się z tym dokumentem gdyż określa on zasady
              oraz sposoby przetwarzania i ochrony danych osobowych. Dokument ten określa także zasady
              stosowania plików „Cookies”.
            </li>
            <li>
              Niniejszym oświadczamy, że przestrzegamy zasad ochrony danych osobowych oraz wszelkich
              uregulowań prawnych, które są przewidziane Ustawą o ochronie danych osobowych oraz
              Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
              sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
              swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.
            </li>
            <li>
              Osoba której dane osobowe są przetwarzane ma prawo zwrócić się do nas celem uzyskania
              wyczerpujących informacji w jaki sposób wykorzystujemy jego dane osobowe. Zawsze w jasny
              sposób staramy się poinformować o danych, które gromadzimy, w jaki sposób je wykorzystujemy,
              jakim celom mają służyć i komu je przekazujemy, jaką zapewniamy ochronę tych danych przy
              przekazaniu innym podmiotom oraz udzielamy informacji o instytucjach z którymi należy się
              skontaktować w razie wątpliwości.
            </li>
            <li>
              Serwis stosuje środki techniczne takie jak: środki ochrony fizycznej danych osobowych, środki
              sprzętowe infrastruktury informatycznej i telekomunikacyjnej, środki ochrony w ramach narzędzi
              programowych i baz danych oraz środki organizacyjne zapewniające należytą ochronę
              przetwarzanych danych osobowych, a w szczególności zabezpieczają dane osobowe przed
              udostępnieniem ich nieupoważnionym osobom trzecim, uzyskaniem przez osobę nieupoważnioną i
              wykorzystaniem ich w niewiadomym celu, a także przypadkową lub celową zmianą, utratą,
              uszkodzeniem lub zniszczeniem takich danych.
            </li>
            <li>
              Na zasadach określonych w Regulaminie oraz w niniejszym dokumencie posiadamy wyłączny
              dostęp do danych. Dostęp do danych osobowych może być również powierzony innym podmiotom
              za pomocą, których dokonuje się płatności, które gromadzą, przetwarzają i przechowują dane
              osobowe zgodnie ze swoimi Regulaminami oraz podmioty, które mają za zadanie realizację
              zamówienia. Dostęp do danych osobowych jest udzielany w/w podmiotom w zakresie niezbędnym i
              tylko takim, który zapewni realizację usług.
            </li>
            <li>
              Dane osobowe są przetwarzane tylko w takich celach na jakie wyrazili Państwo zgodę poprzez
              kliknięcie w odpowiednie pola formularza zamieszczonego w Serwisie lub w inny wyraźny sposób.
              Podstawą prawną przetwarzania Państwa danych osobowych jest zgoda na przetwarzanie danych
              lub wymóg realizacji usługi (np. zamówienie Produktu) którą u nas zamówiłeś (stosownie
              do artykułu 6 ust. 1 lit a i b Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia
              27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
              osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
              (ogólne rozporządzenie o ochronie danych) - RODO.
            </li>
            </ol>
            <h5>§2 Zasady prywatności</h5>
            <ol>
              <li>Poważnie traktujemy prywatność. Charakteryzuje nas szacunek dla prywatności oraz możliwie najpełniejsza i zagwarantowana wygoda z korzystania z naszego usług.</li>
              <li>Cenimy zaufanie, jakim obdarzają nas Użytkownicy, powierzając nam swoje dane osobowe w celu realizacji zamówienia. Zawsze korzystamy z danych osobowych w sposób uczciwy oraz tak, aby nie zawieść tego zaufania, tylko w zakresie niezbędnym do realizacji zamówienia w tym jego przetwarzania.</li>
              <li>Użytkownik ma prawo do uzyskania jasnych i pełnych informacji o tym, w jaki sposób wykorzystujemy jego dane osobowe i do jakich celów są potrzebne. Zawsze w jasny sposób informujemy o danych, które gromadzimy, w jaki sposób i komu je przekazujemy oraz udzielamy informacji o podmiotach, z którymi należy się skontaktować w razie wątpliwości, pytań, uwag.</li>
              <li>W razie wątpliwości odnośnie wykorzystywania przez nas danych osobowych Użytkownika, niezwłocznie podejmiemy działania w celu wyjaśnienia i rozwiania takich wątpliwości, w sposób pełny i wyczerpujący odpowiadamy na wszystkie pytania z tym związane.</li>
              <li>Podejmiemy wszystkie uzasadnione działania, aby chronić dane Użytkowników przed nienależytym i niekontrolowanym wykorzystaniem oraz zabezpieczyć je w sposób kompleksowy.</li>
              <li>Dane Administrator Państwa danych osobowych znajdują się na w zakładce „kontakt” umiejscowionej na stronie internetowej.</li>
              <li>Podstawą prawną przetwarzania Państwa danych osobowych jest art. 6 ust. 1 lit. b) RODO. Podanie
            danych nie jest obowiązkowe, ale niezbędne do podjęcia odpowiednich czynności poprzedzających
            zawarcie umowy oraz jej realizację. Będziemy przekazywać Państwa dane osobowe innym
            odbiorcom, którym powierzono przetwarzanie danych osobowych w imieniu i na naszą rzecz.
            Państwa dane będą przekazywane na podstawie art. 6 ust. 1 lit. f) RODO, gdzie prawnie
            uzasadnionym interesem jest należyte wykonanie umów/zleceń. Ponadto będziemy udostępniać
            Państwa dane osobowe innym partnerom handlowym. Zebrane dane osobowe przechowujemy na
            terenie Europejskiego Obszaru Gospodarczego („EOG”), ale mogą one być także przesyłane do
            kraju spoza tego obszaru i tam przetwarzane. Każda operacja przesyłania danych osobowych jest
            wykonywana zgodnie z obowiązującym prawem. Jeśli dane są przekazywane poza obszar EOG,
            stosujemy standardowe klauzule umowne oraz tarczę prywatności jako środki zabezpieczające w
            odniesieniu do krajów, w przypadku których Komisja Europejska nie stwierdziła odpowiedniego
            poziomu ochrony danych.</li>
              <li>Państwa dane osobowe związane z zawarciem i realizacją umowy o realizacje umów przetwarzane
            będą przez okres ich realizacji, a także przez okres nie dłuższy niż przewidują to przepisy prawa, w
            tym przepisy Kodeksu cywilnego oraz ustawy o rachunkowości, tj. nie dłużej niż przez 10 lat, licząc
            od końca roku kalendarzowego w którym ostatnia umowa została wykonana.</li>
              <li>Państwa dane osobowe przetwarzane w celu zawarcia i wykonania przyszłych umów będą
            przetwarzane do czasu zgłoszenia sprzeciwu.</li>
              <li>Przysługuje Państwu prawo do: dostępu do swoich danych osobowych i otrzymania kopii danych
            osobowych podlegających przetwarzaniu, sprostowania swoich nieprawidłowych danych; żądania
            usunięcia danych (prawo do bycia zapomnianym) w przypadku wystąpienia okoliczności
            przewidzianych w art. 17 RODO; żądania ograniczenia przetwarzania danych w przypadkach
            wskazanych w art. 18 RODO, wniesienia sprzeciwu wobec przetwarzania danych w przypadkach
            wskazanych w art. 21 RODO, przenoszenia dostarczonych danych, przetwarzanych w sposób
            zautomatyzowany.</li>
              <li>Jeżeli uważacie Państwo, że dane osobowe są przetwarzane niezgodnie z prawem, możecie wnieść
            skargę do organu nadzorczego (Urząd Ochrony Danych Osobowych, ul. Stawki 2, Warszawa). Jeśli
            potrzebujecie Państwo dodatkowych informacji związanych z ochroną danych osobowych lub
            chcecie skorzystać z przysługujących praw, skontaktujcie się z nami listownie na adres
            korespondencyjny.</li>
              <li>Dokładamy wszelkich starań, aby chronić przed nieuprawnionym dostępem, nieautoryzowaną
            modyfikacją, ujawnieniem oraz zniszczeniem informacji znajdujących się w naszym posiadaniu. W
            szczególności:
                <ul>
                  <li>Kontrolujemy metody gromadzenia, przechowywania i przetwarzania informacji, w tym fizyczne
            środki bezpieczeństwa, aby chronić przed nieuprawnionym dostępem do systemu.</li>
                  <li>Dostępu do danych osobowych udzielamy jedynie tym pracownikom, kontrahentom oraz
            przedstawicielom, którzy muszą mieć do nich dostęp. Ponadto na mocy umowy są oni
            zobowiązani do zachowania ścisłej poufności, do umożliwienia nam kontroli i sprawdzenia jak
            wywiązują się z powierzonych obowiązków, a w przypadku niewypełnienia tych zobowiązań
            mogą ponieść konsekwencje.</li>
                </ul>
              </li>
              <li>Będziemy przestrzegać wszystkich obowiązujących przepisów i regulacji dotyczących ochrony
            danych i będziemy współpracować z organami zajmującymi się ochroną danych oraz uprawnionymi
            do tego organami ścigania. W przypadku braku przepisów dotyczących ochrony danych, będziemy
            postępować zgodnie z ogólnie przyjętymi zasadami ochrony danych, zasadami współżycia
            społecznego jak i ustalonymi zwyczajami.</li>
              <li>Dokładny sposób ochrony danych osobowych został zawarty w polityce ochrony danych osobowych
            (ODO: polityka bezpieczeństwa, regulamin ochrony danych osobowych, instrukcja zarządzania
            systemem informatycznym) Z przyczyn bezpieczeństwa, ze względu na opisane w niej procedury,
            jest ona do wglądu jedynie dla organów kontroli państwowej.</li>
              <li>W razie pytań, odnośnie sposobu, w jaki postępowania z danymi osobowymi, zapraszamy do
            kontaktu za pomocą strony, z której użytkownik został przekierowany do niniejszej Polityki
            prywatności. Prośba o kontakt zostanie niezwłocznie przekazana do odpowiedniej powołanej do tego
            osoby.</li>
              <li>Użytkownik ma zawsze prawo powiadomić nas, jeśli:
                <ul>
                  <li>nie chce w jakiejkolwiek formie już otrzymywać od nas informacji lub wiadomości;</li>
                  <li>pragnie otrzymać posiadaną przez nas kopię swoich danych osobowych;</li>
                  <li>poprawić, zaktualizować lub usunąć swoje dane osobowe znajdujące się w naszej ewidencji;</li>
                  <li>pragnie zgłosić naruszenia, nienależyte wykorzystanie bądź przetwarzanie swoich danych
            osobowych.</li>
                </ul>
              </li>
              <li>Aby ułatwić nam odpowiedź bądź ustosunkowanie się do podanych informacji, prosimy o podanie imienia i nazwiska oraz dalej idących szczegółów.</li>
            </ol>
            <h5>§3 Zakres i cel zbierania danych osobowych</h5>
            <ol>
              <li>
                Przetwarzamy niezbędne dane osobowe w celu realizacji usług oraz w celach księgowych i tylko takich, tj.:
                <ul>
                  <li>w celu złożenia zamówienia,</li>
                  <li>w celu zawarcia umowy, reklamacji oraz odstąpienia od umowy,</li>
                  <li>wystawienia faktury VAT lub innego paragonu,</li>
                  <li>monitorowania ruchu na naszych stronach internetowych,</li>
                  <li>zbieranie anonimowych statystyk, dla ustalenia, w jaki sposób użytkownicy korzystają z naszej strony internetowej,</li>
                  <li>ustalanie liczby anonimowych użytkowników naszych stron,</li>
                  <li>kontrolowanie jak często pokazywana jest użytkownikom wybrana treść i jaka treść najczęściej,</li>
                  <li>kontrolowanie jak często użytkownicy wybierają daną usługę bądź z poziomu jakiej usługi następuje najczęściej kontakt,</li>
                  <li>badanie zapisów na newslettery i opcje kontaktu,</li>
                  <li>wykorzystanie systemu personalizowanych rekomendacji dla e-commerce,</li>
                  <li>wykorzystanie narzędzia do komunikacji zarówno mailowej jak i w następstwie czego telefonicznej,</li>
                  <li>integracja z portalem społecznościowym,</li>
                  <li>ewentualne płatności internetowe.</li>
                </ul>
              </li>
              <li>
                Zbieramy, przetwarzamy i przechowujemy następujące dane użytkowników:
                <ul>
                  <li>imię i nazwisko,</li>
                  <li>adres zamieszkania,</li>
                  <li>adres do doręczeń (jeśli jest inny niż adres zamieszkania),</li>
                  <li>numer identyfikacji podatkowej (NIP),</li>
                  <li>adres poczty elektronicznej (e-mail),</li>
                  <li>numer telefonu (komórkowy, stacjonarny),</li>
                  <li>datę urodzenia,</li>
                  <li>PESEL,</li>
                  <li>informacje o używanej przeglądarce internetowej,</li>
                  <li>inne dobrowolnie przekazane nam dane osobowe.</li>
                </ul>
              </li>
              <li>Podanie powyższych danych przez jest całkowicie dobrowolne ale także i niezbędne do pełnej realizacji usług.</li>
              <li>
                Cel gromadzenia i przetwarzania lub wykorzystania przez nas danych:
                <ul>
                  <li>marketing bezpośredni, cele archiwalne kampanii reklamowych,</li>
                  <li>realizacja obowiązków nałożonych przepisami prawa poprzez zbieranie informacji o niepożądanych działaniach.</li>
                </ul>
              </li>
              <li>
                Możemy przesyłać dane osobowe do serwerów znajdujących się poza krajem zamieszkania użytkownika lub do podmiotów powiązanych, stron trzecich z siedzibą w innych krajach w tym krajach z obszaru EOG (Europejski Obszar Gospodarczy, EOG ang. European Economic Area, EEA – strefa wolnego handlu i Wspólny Rynek, obejmujące państwa Unii Europejskiej i Europejskiego Stowarzyszenia Wolnego Handlu EFTA) w celu przetwarzania danych osobowych przez takie podmioty w naszym imieniu zgodnie z postanowieniami niniejszej Polityki prywatności oraz obowiązującymi przepisami prawa, zwyczajami jak i regulacjami dotyczącymi ochrony danych.
              </li>
              <li>
                Twoje dane osobowe przechowujemy nie dłużej niż są one potrzebne dla właściwej jakości obsługi i w zależności od trybu i celu ich pozyskania przechowujemy je na czas jej trwania oraz po jej zakończeniu w celach:
                <ul>
                  <li>realizacji obowiązków wynikających z przepisów prawa, przepisów podatkowych i rachunkowych,</li>
                  <li>zapobiegania nadużyciom lub przestępstwom,</li>
                  <li>statystycznych i archiwizacyjnych,</li>
                  <li>Działania marketingowe – na czas trwania umowy, udzielenia odrębnej zgody na przetwarzanie takich danych – do czasu zakończenia działań związanych z obsługą transakcji, wniesienia przez Ciebie sprzeciwu wobec takiego przetwarzania lub wycofania zgody,</li>
                  <li>Działania okołosprzedażowe i promocyjne – np. konkursy, akcje promocyjne – na czas trwania i rozliczenia takich akcji,</li>
                  <li>Działalność operacyjna - do czasu przedawnienia obowiązków nałożonych przez Rozporządzenie RODO oraz odpowiednie przepisy krajowe, celem wykazania rzetelności w przetwarzaniu danych osobowych,</li>
                  <li>dochodzenia wszelkich roszczeń związanych ze zrealizowaną umową.</li>
                </ul>
              </li>
              <li>
                Mając na uwadze okoliczności, że w wielu krajach, do których są przesyłane niniejsze dane osobowe nie obowiązuje taki sam poziom ochrony prawnej danych osobowych, jaki obowiązuje w kraju użytkownika. Do danych osobowych użytkownika przechowywanych w innym kraju dostęp zgodnie z prawem tam obowiązującym, dostęp mogą uzyskać na przykład: sądy, organy odpowiedzialne za egzekwowanie prawa i bezpieczeństwo narodowe, zgodnie z przepisami obowiązującymi w tym kraju. Z zastrzeżeniem zgodnych z prawem próśb o ujawnienie danych, zobowiązujemy się wymagać od podmiotów przetwarzających dane osobowe poza krajem użytkownika podjęcia działań w celu ochrony danych w adekwatny sposób do regulacji ich prawa krajowego.
              </li>
            </ol>
            <h5>§4 Polityka „Cookies”</h5>
            <ol>
              <li>Zbieramy w sposób automatyczny informacje zawarte w plikach cookies w celu gromadzenia danych Użytkownika. Plik Cookies to mały fragment tekstu, który jest wysyłany do przeglądarki Użytkownika i który przeglądarka wysyła z powrotem przy następnych wejściach na witrynę. Używane są głównie do utrzymywania sesji np. poprzez wygenerowanie i odesłanie tymczasowego identyfikatora po logowaniu. Wykorzystujemy pliki Cookies „sesyjne” przechowywane na urządzeniu końcowym Użytkownika do czasu jego wylogowania, wyłączenia strony internetowej lub wyłączenia przeglądarki internetowej oraz pliki Cookies „stałe” przechowywane na urządzeniu końcowym Użytkownika przez czas określony w parametrach plików Cookies lub do czasu ich usunięcia przez Użytkownika.</li>
              <li>Pliki Cookies dostosowują i optymalizują stronę i jej ofertę dla potrzeb Użytkowników poprzez takie działania jak tworzenie statystyk odsłon oraz zapewnienie bezpieczeństwa. Pliki Cookies niezbędne są również do utrzymania sesji po opuszczeniu strony internetowej.</li>
              <li>Administrator przetwarza dane zawarte w plikach Cookies za każdym razem gdy strona jest odwiedzana przez odwiedzających w następujących celach:
                <ul>
                  <li>optymalizacji korzystania ze strony;</li>
                  <li>identyfikacji Usługobiorców jako w danej chwili zalogowanych;</li>
                  <li>przystosowania, grafiki, opcji wyboru oraz wszelkiej innej zawartości strony do indywidualnych preferencji Usługobiorcy;</li>
                  <li>zapamiętywania uzupełnianych w sposób automatyczny i manualny, zamieszczanych danych z Formularzy Zamówienia lub podanych przez odwiedzającego danych logowania;</li>
                  <li>gromadzenia i analizowania anonimowych statystyk przedstawiających sposób korzystania ze strony w panelu administracyjnym oraz google analytics;</li>
                  <li>tworzenia list remarketingowych na podstawie informacji o preferencjach, zachowaniu, sposobie korzystania zainteresowaniach ze Strony oraz zbierania danych demograficznych, a następnie udostępnianie tych list w AdWords oraz Facebook Ads;</li>
                  <li>tworzenia segmentów danych na podstawie informacji demograficznych, zainteresowań, upodobań w wyborze oglądanych produktów/usług;</li>
                  <li>wykorzystywania danych demograficznych i danych o zainteresowaniach w raportach Analytics.</li>
                </ul>
              </li>
              <li>Użytkownik w każdej chwili za pomocą swojej przeglądarki internetowej może całkowicie zablokować i skasować gromadzenie plików Cookies.</li>
              <li>Zablokowanie przez Użytkownika możliwości gromadzenia plików Cookies na jego urządzeniu może utrudnić lub uniemożliwić korzystanie z niektórych funkcjonalności strony do czego Użytkownik jest w pełni uprawniony ale musi w takiej sytuacji mieć świadomość z ograniczeń funkcjonalności.</li>
              <li>Użytkownik, który nie chce wykorzystywania plików „cookies” w opisanym powyżej celu w każdej chwili może usunąć je ręcznie. Do zapoznania się ze szczegółową instrukcją postępowania należy odwiedzić stronę internetową producenta używanej przeglądarki internetowej z której aktualnie korzysta Użytkownik.</li>
            </ol>
            <p>Więcej informacji na temat Cookies dostępnych jest w menu pomocy każdej przeglądarki internetowej. Przykładowe przeglądarki internetowe obsługujące wspomniane pliki „Cookies”:</p>
            <ul>
              <li>Ustawienia plików cookies Internet Explorer</li>
              <li>Ustawienia plików cookies Chrome</li>
              <li>Ustawienia plików cookies Firefox</li>
              <li>Ustawienia plików cookies Opera</li>
              <li>Ustawienia plików cookies Safari</li>
              <li>Pliki cookies w Android</li>
              <li>Pliki cookies w Blackberry</li>
              <li>Pliki cookies w iOS (Safari)</li>
              <li>Pliki cookies w Windows Phone</li>
            </ul>
            <h5>§5 Prawa i obowiązki</h5>
            <ol>
              <li>Mamy prawo a w przypadkach prawem określonych także i ustawowy obowiązek do przekazania wybranych bądź wszystkich informacji dotyczących danych osobowych organom władzy publicznej bądź osobom trzecim, które zgłoszą takie żądanie udzielenia informacji na podstawie obowiązujących przepisów prawa polskiego.</li>
              <li>Użytkownik ma prawo dostępu do treści swoich danych osobowych, które udostępnia, Użytkownik może te dane poprawiać, uzupełniać w każdym czasie, a także ma prawo do żądania aby je usunięto ze swoich baz danych bądź zaprzestano je przetwarzać, bez podawania jakiekolwiek przyczyny. W celu realizacji swoich praw Użytkownik może w każdym czasie przesłać stosowaną wiadomość na adres poczty elektronicznej bądź w inny sposób, który dostarczy/przekaże takie żądanie.</li>
              <li>Przetwarzanie danych osobowych osób fizycznych będących naszymi klientami opiera się na:
                <ul>
                  <li>usprawiedliwionym interesie jako administratora danych (np. w zakresie tworzenia bazy danych, czynności analitycznych i profilujących, w tym czynności dot. analizy korzystania z produktów, marketingu bezpośredniego produktów własnych, zabezpieczeniu dokumentacji na potrzeby obrony przed ewentualnymi roszczeniami lub na potrzeby dochodzenia roszczeń)</li>
                  <li>zgody (w tym w szczególności zgody na e-mail marketing lub telemarketing)</li>
                  <li>wykonania zawartej umowy</li>
                  <li>obowiązków wynikających z prawa (np. prawa podatkowego lub przepisów o rachunkowości).</li>
                </ul>
              </li>
              <li>Przetwarzanie danych osobowych osób fizycznych będących potencjalnymi klientami opiera się na:
                <ul>
                  <li>usprawiedliwionym interesie administratora danych (np. w zakresie tworzenia bazy danych, marketingu bezpośrednim produktów własnych)</li>
                  <li>zgody (w tym w szczególności zgody na e-mail marketing lub telemarketing)</li>
                </ul>
              </li>
              <li>Żądanie ze strony Użytkownika usunięcia danych osobowych lub zaprzestania ich przetwarzania przez może skutkować całkowitym brakiem możliwości realizacji usług przez bądź ich poważnym ograniczeniem.</li>
              <li>Szczególną wagę przykładamy do kwestii profilowania i wskazujemy, że:
                <ul>
                  <li>na potrzeby profilowania przetwarzamy z reguły dane, które upr

            zednio podlegały szyfrowaniu ssl;</li>
                  <li>wykorzystujemy do tego typowe dane: adres e-mail i IP lub cookies</li>
                  <li>profilujemy w celu analizy lub prognozy osobistych preferencji oraz zainteresowań osób korzystających z naszych Serwisów lub produktów lub usług i dopasowywania treści znajdujących się w naszych Serwisach lub produktach do tych preferencji</li>
                  <li>profilujemy w celach marketingowych, tj. dopasowania oferty marketingowej do ww. preferencji.</li>
                </ul>
              </li>
              <li>Zobowiązujemy się postępować zgodnie z obowiązującymi przepisami prawa i zasadami współżycia społecznego.</li>
              <li>Informacja o pozasądowym rozpatrywaniu sporów konsumenckich. Podmiotem uprawnionym w rozumieniu ustawy o pozasądowym rozpatrywaniu sporów konsumenckich jest Rzecznik Finansowy, którego adres strony internetowej jest następujący: <a href="www.rf.gov.pl">www.rf.gov.pl</a>.</li>
            </ol>
            <h5>§6 Podstawowe zasady bezpieczeństwa</h5>
            <ol>
              <li>Każdy użytkownik powinien dbać o swoje własne bezpieczeństwo danych oraz o bezpieczeństwo swoich urządzeń, które służą dostępowi do sieci Internet. Takie urządzenie powinno bezwzględnie posiadać program antywirusowy z aktualną regularnie uzupełnianą bazą definicji, typów i rodzajów wirusów, bezpieczną wersję przeglądarki internetowej z której korzysta oraz włączoną zaporę sieciową. Użytkownik powinien sprawdzać, czy system operacyjny i programy zainstalowane na nim posiadają najnowsze i kompatybilne aktualizacje, ponieważ w atakach wykorzystywane są błędy wykryte w zainstalowanym oprogramowaniu.</li>
              <li>Dane dostępowe do usług oferowanych w sieci Internet to – np. loginy, hasła, PIN, certyfikaty elektroniczne itp., – powinny być zabezpieczone w miejscu niedostępnym dla innych i niemożliwym do włamania z poziomu sieci internetowej. Nie należy ich ujawniać lub przechowywać na urządzeniu w formie, która umożliwia nieautoryzowany dostęp i odczyt przez osoby do tego nieuprawnione.</li>
              <li>Ostrożność podczas otwierania dziwnych załączników lub klikania odnośników w wiadomościach mailowych, których się nie spodziewaliśmy np. od nieznanych nadawców, bądź z folderu spam.</li>
              <li>Zaleca się uruchomienie w przeglądarce internetowej filtrów antyphishingowych czyli narzędzi, które sprawdzają, czy wyświetlona strona internetowa jest autentyczna i nie służy wyłudzaniu informacji, np. poprzez podszywanie się pod osobę lub instytucję.</li>
              <li>Pliki powinny być pobierane tylko i wyłącznie z zaufanych miejsc, serwisów i stron. Nie zalecamy instalowania oprogramowania z niezweryfikowanych źródeł zwłaszcza od nieznanych wydawców o niesprawdzonej opinii. Dotyczy to również urządzeń przenośnych, np. smartfonów, tabletów.</li>
              <li>Podczas używania domowej sieci bezprzewodowej Wi-Fi należy ustalić takie hasło aby było bezpieczne i trudne do złamania, nie powinno być nim żaden wzór i ciąg znaków który jest łatwy do odgadnięcia (np. nazwa ulicy, imię gospodarza, data urodzin itp.). Rekomenduje się także korzystanie najwyższych możliwych standardów szyfrowania sieci bezprzewodowych Wi-Fi, które są możliwe do uruchomienia na posiadanym sprzęcie np. WPA2.</li>
            </ol>
            <h5>§7 Korzystanie z wtyczek Social Media</h5>
            <ol>
              <li>Wtyczki tak zwane plug-ins portali społecznościowych facebook.com i Twitter oraz innych, mogą znajdować się na naszych stronach. Związane z nimi usługi dostarczane są odpowiednio przez firmy Facebook Inc. i Twitter Inc.</li>
              <li>Facebook obsługiwany jest przez Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA Facebook. Aby zobaczyć wtyczki Facebook przejdź do: "https://developers.facebook.com/docs/plugins">https://developers.facebook.com/docs/plugins</li>
              <li>Twitter obsługiwany jest Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA. Aby zobaczyć wtyczki Twittera przejdź do: "https://dev.twitter.com/web/tweet-button">https://dev.twitter.com/web/tweet-button</li>
              <li>Wtyczka przekazuje jej dostawcy jedynie informację o tym, do których z naszych stron internetowych miałeś dostęp i w jakim czasie. Jeśli podczas oglądania naszej strony bądź przebywania na niej, użytkownik jest zalogowany do swojego konta znajdującego się np. na Facebooku lub Twitterze, dostawca jest w stanie łączyć Twoje zainteresowania, preferencję informacyjne, oraz inne dane, pozyskane np. poprzez kliknięcie przycisku Lubię to czy pozostawienie komentarza, bądź wpisanie nazwy profilu w wyszukiwanych. Taka informacja również zostanie przekazana przez przeglądarkę bezpośrednio do dostawcy.</li>
              <li>Więcej bardziej szczegółowych informacji na temat gromadzenia i wykorzystywania danych przez Facebook lub Twitter i na temat ochrony prywatności można znaleźć na poniższych stronach:</li>
              <ul>
                <li>Ochrona danych/porady dot. prywatności wydane przez Facebook: http://www.facebook.com/policy.php">http://www.facebook.com/policy.php</li>
                <li>Ochrona danych/porady dot. prywatności wydane przez Twitter: https://twitter.com/privacy">https://twitter.com/privacy</li>
              </ul>
              <li>Aby uniknąć odnotowania wizyty na wybranym koncie użytkownika przez Facebook lub Twitter na naszej stronie internetowej musisz wylogować się ze swojego konta przed rozpoczęciem przeglądania naszych stron internetowych.</li>
            </ol>
            </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>