<section class="section-two bg-light" id="bookroom">
    <div class="container" style="margin-top: 20px;">
      <div class="row justify-content-center">
        <div class="col-lg-12" style="background-color: white; border-radius: 10px; padding: 15px; border: 1px rgb(236, 236, 236) solid;">
          <!-- <form class="p-4 shadow bg-white rounded"> -->
            <div class="row text-start">
                <div class="col-lg-5">
                    <div class="row align-items-center">

                        <div class="col-lg-8 col-7" >  
                            <label class="form-label">Dzień przyjazdu</label>
                             <input class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true" [convertModelValue]="true" altFormat="d.m.Y" dateFormat="d-m-Y" 
                             [(ngModel)]="dataModel.checkIn" [minDate]="Today">
                        </div>
                        <div class="col-lg-4 col-5" >
                            <label class="form-label" >Czas przyjazdu</label>
                            <input type="time" min="0" autocomplete="off" id="adult" required="" class="form-control" value="12" [(ngModel)]="dataModel.hourIn">
                        </div>
                    </div>
                </div>
                    
                <div class="col-lg-5">
                    <div class="row align-items-center">

                        <div class="col-lg-8 col-7">
                          <label class="form-label">Dzień wyjazdu</label>
                          <input class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true" [convertModelValue]="true" altFormat="d.m.Y" dateFormat="d-m-Y" 
                             [(ngModel)]="dataModel.checkOut" [minDate]="dataModel.checkIn"> 
                        </div>            
                        <div class="col-lg-4 col-5" >
                          <label class="form-label" >Czas wyjazdu</label>
                          <input type="time" min="0" autocomplete="off" id="adult" required="" class="form-control" value="12" [(ngModel)]="dataModel.hourOut">
                        </div>
                    </div>
                </div>
                    <div class="mt-lg-4 pt-2 col-lg-2">
                        <div class="d-grid">
                            <input type="submit" id="search" name="search" class="searchbtn btn btn-primary" value="Szukaj" (click)="Search()">
                        </div>
                    </div>
                  <!--end col-->
            </div>
          <!-- </form> -->
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>

  <div class="container">
    <div class="row">
      <!-- Blog Post Start -->
      <div class="col-lg-12 col-12">
        <div class="row">
          <div class="col-12 mb-4 pb-2" *ngFor="let data of parkingInstanceList; index as i">
            <div class="card blog rounded border-0 shadow overflow-hidden" (click)="click(data.name , i)">
              <div class="row align-items-center g-0">
                <div class="col-lg-4">
                  <img src="{{data.srcImage[0].linkOfPicture}}" class="img-fluid"  alt="">
                  <div class="overlay bg-dark"></div>
                  <div class="author">
                    <small class="text-light user d-block"><i class="uil uil-user"></i> {{data.name}}</small>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-5 col-mb-4">
                  <div class="card-body content" >
                    <h5><a href="javascript:void(0)" class="card-title title text-dark">{{data.name}}</a></h5>
                    <p class="text-muted mb-0">{{data.content}}</p>
                    <div class="post-meta d-flex justify-content-between mt-3">
                      <ul class="list-unstyled mb-0">
                        <li class="list-inline-item me-2 mb-0 like"*ngFor="let link of atuts" >  
                          <a href="javascript:void(0)" class="text-muted like">
                            <i [ngClass]="link.icon"></i>{{link.name}}
                        </a>
                        </li>
                      </ul>
                      <ul class="list-unstyled mb-0">
                        <li class="list-inline-item me-2 mb-0 "*ngFor="let link of atuts2" >{{link.name}} <a href="javascript:void(0)" class="text-muted like"><i
                          class="uil uil-check align-middle"></i> </a></li>
                          <li>
                          </li>
                          <div class="mt-3 text-center text-align" style="border: 1px gray dotted; border-radius: 10px; background-color: rgb(234, 243, 235);">
                            <span class="mdi mdi-bus-side me-2 h4" style="color: green;"></span>
                            <a>Transport 5 min</a>
                          </div>
                        </ul>
                      </div>
                  </div>
                </div>
                <div class="col-lg-3 col-mb-2">
                  <div class="card-body content text-center" >

                    <h5 >Rezerwuj od:</h5>
                    <h4>{{data.bookingOffers[0].price/100 |number: '1.2-2'}} zł</h4>
                    <button 
                    [routerLink]="['/rezerwacja']" [queryParams]="{ Nazwa: data.urlParkingName ,Oferta: data.bookingOffers[0].name ,rozpoczecie:ChecInUrl,zakonczenie: ChecOutUrl }"class="btn btn-primary">Rezerwuj <i
                    class="uil uil-angle-right-b align-middle "></i></button>
                    <h6 class="mt-2">Cena za {{diffInDays}} dni</h6>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
            <div class="col-lg-12 rounded border-0 shadow overflow-hidden">
              <div class="row">
                <div class="col-12 " *ngIf="data.name==show">

                    <div  class="row">
                      <div class="col-12  btn-group">
                        <button type="button" *ngFor="let option of options" [ngClass]="{'btn-custom': actualWindow==option}" class="btn " (click)="setActualWindow(option)">{{option}}</button>
                      </div>
                      <!-- Opis -->
                      <div class="col-12" *ngIf="actualWindow=='Opis'">
                        <div class="text-center p-4">{{data.description}}</div>
                        <div class="row">

                          <div *ngFor="let items of data.bookingOffers " class=" card-body col-lg-4 text-center rounded border-0 shadow " >
                            <h3>{{items.name== 'outside' ? "Na zewnątrz": items.name == 'garage' ?"Garaż": "Wiata" }}</h3>
                            <h5 >Cena za {{diffInDays}} dni:</h5>
                            <h4>{{items.price/100 | number:'1.2-2'}} zł</h4>
                            <button [routerLink]="['/rezerwacja']" [queryParams]="{ Nazwa: data.urlParkingName, Oferta: items.name, rozpoczecie:ChecInUrl ,zakonczenie: ChecOutUrl }" class="btn btn-primary">Rezerwuj <i
                            class="uil uil-angle-right-b align-middle "></i></button>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="actualWindow=='Zdjęcia'">
                        <ngb-carousel>
                          <ng-template ngbSlide *ngFor="let picture of data.srcImage">
                              <div class="picsum-img-wrapper">
                                  <img src="{{picture.linkOfPicture}}" class="d-block w-100" alt="...">
                              </div>
                          </ng-template>
                      </ngb-carousel>                  
                    </div>
                    <div *ngIf="actualWindow=='Opinie'" >
                      <h3 class="text-center">Ostatnio dodane opinie</h3>
                      <div *ngFor="let opinion of data.opinions">
                        <div class="row"  style="margin:10px; background-color: rgb(255, 255, 255); border-radius: 10px; border: 1px rgb(201, 201, 201) solid ;">
                          <div class="co-lg-12">
                            {{opinion.whoSetOpinion}}
                            <app-star-rating  [maxRating]="5" [currentRating]="5"></app-star-rating>
                          </div>
                          <div class="col-lg-12 text-center">
                            {{opinion.opinionDescription}}
                          </div>
                        </div>
                      </div>
                    </div>

                      <div *ngIf="actualWindow=='Mapa'" class="ratio ratio-16x9 col-12">
                        <iframe [src]="urlSafe"  (load)="test()" width="600" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      </div>




                    </div>
                  </div>
              </div>
            </div>
            <!--end blog post-->
          </div>
        </div>
    </div>

<section *ngIf="!parkingInstanceList">
    <div class="d-flex justify-content-center" style="padding-top: 100px;"> 
      <span class="sr-only" style="text-align: center; color: black; font-size: 35px;">Pobieranie parkingów...</span>
    </div>
    <div class="d-flex justify-content-center" style="padding-top: 20px; padding-bottom: 20px;">
      <div class="spinner-border text-primary" style="width: 5rem; height: 5rem;" role="status">
      </div>
  </div>
</section>

<!-- SEO componet -->
<section>

    <h1 class="mt-4 mb-4 h3">Leave and Fly - Wygodne i Bezpieczne Parkingi Lotnicze w Pyrzowicach</h1>
    
    <p>Wakacje i podróże służbowe to doskonałe sposoby na odpoczynek i zwiedzanie nowych miejsc. Dla wielu z nas podróżowanie samolotem z lotniska Pyrzowice w Katowicach jest wygodnym rozwiązaniem. Jednak kluczowym elementem udanej podróży jest znalezienie odpowiedniego i bezpiecznego miejsca na zaparkowanie samochodu na czas naszej nieobecności. W celu ułatwienia tego procesu, powstała wyszukiwarka parkingów lotniczych, która pozwala zarezerwować parking przed wylotem, zapewniając spokój i wygodę zarówno przed, jak i po podróży.</p>
    
    <h2 class="h4">Dlaczego Warto Skorzystać z Wyszukiwarki Parkingów Lotniczych w Pyrzowicach?</h2>
    
    <ol>
      <li>Bezpieczeństwo i Monitorowanie 24/7: Dobre parkingi lotnicze w Pyrzowicach oferują monitoring 24/7 oraz zabezpieczenia, które zapewniają ochronę Twojego pojazdu przez cały czas, kiedy jesteś poza krajem. To znacznie zmniejsza ryzyko kradzieży i uszkodzeń samochodu.</li>
      <li>Bezstresowy Wyjazd: Rezerwując parking online przed wylotem, masz pewność, że miejsce na parkingu jest zarezerwowane specjalnie dla Ciebie. Nie musisz martwić się o znalezienie odpowiedniego miejsca na ostatnią chwilę.</li>
      <li>Dogodna Lokalizacja: Wyszukiwarka parkingów pomoże Ci znaleźć parkingi lotnicze, które są usytuowane blisko terminali lotniska w Pyrzowicach. To minimalizuje czas podróży między parkowaniem a lotniskiem, co jest szczególnie korzystne, gdy masz ograniczony czas na dojazd.</li>
      <li>Różnorodność Opcji: Dobra wyszukiwarka parkingów lotniczych oferuje szeroki wybór różnych miejsc, od zadaszonych do odkrytych, standardowych do luksusowych. Dzięki temu możesz dostosować opcję parkingu do swoich preferencji i budżetu.</li>
      <li>Prostota Rezerwacji: Zarezerwowanie miejsca na parkingu jest bardzo proste i można to zrobić online za pomocą kilku kliknięć. To znacznie oszczędza czas i ułatwia organizację podróży.</li>
    </ol>
    
    <h2 class="h4">Zarezerwuj Parking Online - Łatwe i Szybkie!</h2>
    
    <p>Zdecydowanie warto skorzystać z wyszukiwarki parkingów lotniczych w Pyrzowicach, aby zapewnić sobie wygodę i spokój podczas podróży. Poprzez rezerwację miejsca parkingowego przed wylotem, masz pewność, że Twój samochód będzie bezpieczny i gotowy do odbioru po powrocie.</p>
    
    <p>Nie czekaj do ostatniej chwili - zarezerwuj parking online już teraz! Dzięki temu Twoja reklama na stronie wyszukiwarki parkingów w Pyrzowicach będzie lepiej pozycjonowana w wynikach wyszukiwania Google ads, a potencjalni klienci z łatwością znajdą Twoją ofertę. Wygodne i bezpieczne parkingi lotnicze to klucz do udanej podróży, dlatego skorzystaj z tej wyjątkowej usługi już dziś!</p>
    
</section>
  
  