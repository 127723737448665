import { NewsletterSubscribeModel } from './../../core/Model/Other/Newsletter/NewsletterSubscribeModel';
import { NewsletterResponseModel } from './../../core/Model/Other/Newsletter/NewsletterResponseModel';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NewsletterService } from 'src/app/core/services/Newsletter/newsletter.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  providers: [NewsletterService]
})

/***
 * Footer Component
 */
export class FooterComponent implements OnInit {

  @Input() footerVariant: string;
  @Input() hideFooter: boolean;

  newsForm!: UntypedFormGroup;
  submitted = false;

  //Get Year
  year = new Date().getFullYear()

  constructor(private formBuilder: UntypedFormBuilder,
    private newsletter: NewsletterService) { }

  ngOnInit(): void {
    /**
     * Form Validatyion
     */
    this.newsForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get form() { return this.newsForm.controls; }

  newsletterEmail:string='';
  response:boolean= true;
  badRequest:boolean= false;
  communicateRequest:Array<string>;

  subscribeNewsletter(){
    let rest : NewsletterSubscribeModel={
      email:this.newsletterEmail
    }

    this.newsletter.subscribe(rest).subscribe(data =>{
      if(!data.success){
        this.badRequest= true;
        this.communicateRequest =data.validationErrors;
      }
      else{
        this.badRequest= false;
        this.response = false;
      }

    }
    ,error => {
      console.log(error)
    })
  }
  /**
   * On submit form
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newsForm.invalid) {
      return;
    }
  }

}
