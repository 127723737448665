import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ResponseOrderModel } from 'src/app/core/Model/AccessModel/ResponseOrderModel';
import { StatusAnsswerModel } from 'src/app/core/Model/AccessModel/StatusAnsswerModel';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  conetingString :string = environment.api_url + "Order/LafCreateReservation";

    PostOrder(Object:any) : Observable<ResponseOrderModel> {
      return this.http.post<ResponseOrderModel>(this.conetingString,Object) /* context: HttpContextToken('test') */

    }

    ChceckStatusOrder(Object:any): Observable<StatusAnsswerModel>{
      return this.http.post<StatusAnsswerModel>( environment.api_url + 'Order/CheckStatusPayment',Object)
    }
}
