import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthCoverLoginComponent } from './auth/auth-cover-login/auth-cover-login.component';
import { AuthCoverRePasswordComponent } from './auth/auth-cover-re-password/auth-cover-re-password.component';
import { AuthCoverSignupComponent } from './auth/auth-cover-signup/auth-cover-signup.component';
import { MasterPageComponent } from './core/used/master-page/master-page.component';
import { PageComingsoonComponent } from './core/components/page-comingsoon/page-comingsoon.component';
import { PageErrorComponent } from './core/components/page-error/page-error.component';
import { PageThankyouComponent } from './core/components/page-thankyou/page-thankyou.component';
import { PageMaintenanceComponent } from './core/components/page-maintenance/page-maintenance.component';
import { EmailAlertComponent } from './email/email-alert/email-alert.component';
import { EmailConfirmationComponent } from './email/email-confirmation/email-confirmation.component';
import { EmailInvoiceComponent } from './email/email-invoice/email-invoice.component';
import { EmailPasswordResetComponent } from './email/email-password-reset/email-password-reset.component';
import { SwitcherComponent } from './shared/switcher/switcher.component';
import { BookingFormComponent } from './core/used/booking-form/booking-form.component';
import { ListSearchComponent } from './core/used/list-search/list-search.component';
import { IndexComponent } from './core/used/index/index.component';
import { ThanksForOrderComponent } from './core/used/thanks-for-order/thanks-for-order.component';
import { PagePrivacyComponent } from './core/used/page-privacy/page-privacy.component';
import { ParkingRegulaminComponent } from './shared/parking-regulamin/parking-regulamin.component';
import { SideRegulaminComponent } from './core/used/side-regulamin/side-regulamin.component';
import { PageContactComponent } from './core/used/page-contact/page-contact.component';
import { PageAboutusComponent } from './core/used/page-aboutus/page-aboutus.component';



const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { path: '', component: IndexComponent },
      { path: 'index', component: IndexComponent },
      { path: '#', component: SwitcherComponent },
      { path: 'page-aboutus', component: PageAboutusComponent },
      
      { path: 'parking-lotnisko-okecie', component: ListSearchComponent },
      { path: 'parking-lotnisko-modlin', component: ListSearchComponent },
      { path: 'parking-lotnisko-warszawa-radom', component: ListSearchComponent },
      { path: 'parking-lotnisko-balice', component: ListSearchComponent },
      { path: 'parking-lotnisko-pyrzowice', component: ListSearchComponent },
      { path: 'parking-lotnisko-pyrzowice/:ParkingURL', loadChildren: () => import('./component/ParkingView/parking-view.module').then(m => m.ParkingViewModule) },
      { path: 'parking-lotnisko-wroclaw', component: ListSearchComponent },
      { path: 'parking-lotnisko-poznan', component: ListSearchComponent },
      { path: 'parking-lotnisko-gdansk', component: ListSearchComponent },
      { path: 'parking-lotnisko-bydgoszcz-szwederowo', component: ListSearchComponent },
      { path: 'parking-lotnisko-lodz', component: ListSearchComponent },
      { path: 'parking-lotnisko-rzeszow', component: ListSearchComponent },
      { path: 'parking-lotnisko-lublin', component: ListSearchComponent },
      
      { path: 'kontakt', component: PageContactComponent },
      { path: 'rezerwacja', component: BookingFormComponent },
      { path: 'successful_creation_of_a_reservation/:orderId', component:ThanksForOrderComponent},
      { path: 'page-privacy', component: PagePrivacyComponent},
      { path: 'regulamin', component: SideRegulaminComponent},
      { path: 'regulamin_parkingu/:ParkingURL', component: ParkingRegulaminComponent},
    ]
  },
  { path: 'zaloguj', component: AuthCoverLoginComponent },
  { path: 'przywroc-haslo', component: AuthCoverRePasswordComponent },
  { path: 'zarejestruj', component: AuthCoverSignupComponent },
  
  { path: 'page-comingsoon', component: PageComingsoonComponent },
  { path: 'page-error', component: PageErrorComponent },
  { path: 'page-thankyou', component: PageThankyouComponent },
  { path: 'page-maintenance', component: PageMaintenanceComponent },

  { path: 'email-alert', component: EmailAlertComponent },
  { path: 'email-confirmation', component: EmailConfirmationComponent },
  { path: 'email-invoice', component: EmailInvoiceComponent },
  { path: 'email-password-reset', component: EmailPasswordResetComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top",
    scrollOffset: [0, 0],
    // Enable scrolling to anchors
    anchorScrolling: "enabled", initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
