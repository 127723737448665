import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataLayerService } from './core/services/WindowReferenceService/DataLayerService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Najlepsze parkingi lotnicze';

  constructor(private router: Router) {

    // this.router.events.subscribe(event=> { // subscribe to router events
    //   if (event instanceof NavigationEnd) //if our event is of our interest
    //   {
    //       this._dataLayerService.logPageView(event.url) //call our dataLayer service's page view method to ping home with the url value.
    //   }
  
    /**
     * Unicons icon refreshed on route change.
     */
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        // window['Unicons']();
      }

      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
}
