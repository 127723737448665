import { environment } from './../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { contactModel } from '../../Model/Contact/contactModel';

@Component({
  selector: 'app-page-contact',
  templateUrl: './page-contact.component.html',
  styleUrls: ['./page-contact.component.css']
})

export class PageContactComponent implements OnInit {

  Menuoption = 'center';
  Settingicon = true
  constructor(
    private modalService: NgbModal,
    private http: HttpClient) 
    { }

  ngOnInit(): void {
  }


  contact:contactModel= {
    name:'',
    email:'',
    topic:'',
    description:''
  }

  link:string = environment.api_url+ "Contact";

  successfullySent:boolean= false; 

  submitForm() {
    this.http.post(this.link, this.contact)
      .subscribe(
        response => {
          this.successfullySent= true;
          console.log(response);
          // Dodaj kod obsługi sukcesu
        },
        error => {
          console.error('Form submission failed');
          // Dodaj kod obsługi błędu
        }
      );
  }
}
