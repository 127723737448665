import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-thanks-for-order',
  templateUrl: './thanks-for-order.component.html',
  styleUrls: ['./thanks-for-order.component.css']
})
export class ThanksForOrderComponent {
  title = 'Dziękujemy za rezerwacje!';
  communicat:string='';
  communicat2:string='';
  numberRezwer: string="";

  constructor(private route: ActivatedRoute,private titleService:Title){
    this.titleService.setTitle(this.title);
    this.communicat="Dziękujemy! Rezerwacja zostało utworzona!"
    this.communicat2 ="Otrzymasz wiadomość e-mail z potwierdzeniem rezerwacji ze szczegółami."
  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      const parametrValue = params['orderId'];
      this.numberRezwer = parametrValue;
      console.log(parametrValue); 
    });
  }

}
