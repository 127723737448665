import { Injectable } from '@angular/core';
import { HttpClient, HttpContextToken } from '@angular/common/http'
import { resoultSearch } from '../../Model/ParkList/resoultSearch';
import { ParkingSearchOptions } from '../../Model/ParkList/ParkingSearchOptions';

import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { dataPiker } from '../../Model/ParkList/dataPiker';
import { parkingSearchOptionsMock } from '../../mocks/ParkingSearchOptionsMock';
import { parkingInstanceList } from '../../Model/ParkList/parkingInstanceList';

@Injectable({
  providedIn: 'root'
})
export class ParkListApiService {

  constructor(
    private http: HttpClient) { }

    option :ParkingSearchOptions =  parkingSearchOptionsMock;

    link :string = environment.api_url + "ParkingInstance";

    data : dataPiker = {
      checkIn: new Date(),
      checkOut: new Date('2002-09-23')
    };

    UpdateTimeToSearch(){
      if(this.data.checkOut?.getDay==new Date('2002-09-23').getDay)
        this.data.checkIn?.setDate( this.data.checkOut.getDate()+ 10);
    }

    addDays(numOfDays: number, date = new Date()) {
      this.data.checkOut?.setDate(this.data.checkIn.getDate() + numOfDays);
    
      return date;
    }

    GetListParksResults(option:ParkingSearchOptions) : Observable<resoultSearch> {
      this.UpdateTimeToSearch()
      return this.http.post<resoultSearch>(this.link +"/GetListParkingMein",option);    
    }

    GetParkingByUrlName(urlParkingName:string) : Observable<parkingInstanceList>{
      return this.http.get<parkingInstanceList>(this.link +"/GetParking/"+urlParkingName);
    }
}
