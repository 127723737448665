import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { NewsletterSubscribeModel } from '../../Model/Other/Newsletter/NewsletterSubscribeModel';
import { NewsletterResponseModel } from '../../Model/Other/Newsletter/NewsletterResponseModel';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(private http: HttpClient) {}

  conetingString:string = environment.api_url + "Newsletter/subscribe";

  subscribe(newsletterSubscribeModel :NewsletterSubscribeModel) : Observable<NewsletterResponseModel> {
    return this.http.post<NewsletterResponseModel>(this.conetingString,newsletterSubscribeModel)
  }
}
