<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home"
  style="background: url('assets/images/travel/bg.jpg') center center;">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="title-heading mt-4">
          <h1 class="display-4 fw-bold text-white title-dark mb-3">Tutaj rozpoczyna się <br>  twoja przygoda</h1>
          <p class="para-desc text-white-50">Oferujemy usługi parkingowe samochodów dla osób wybierjących się na wakcje. Klienci mogą rezerwować miejsca parkingowe, zobaczyć dostępność i ceny, a także skorzystać z opcji dostarczenia i odbioru z lotniska.</p>
          <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-icon btn-pills btn-lg btn-light">
              <i-feather name="arrow-down" class="icons"></i-feather>
            </a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="card shadow rounded border-0 ms-lg-5">
          <div class="card-body">
            <h5 class="card-title">Zarezerwuj swoje miejsce postojowe</h5>

            <!-- <form class="login-form"> -->
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Nazwa portu lotniczego</label>
                    <div class="form-icon position-relative">
                      <!-- <i-feather name="map-pin" class="fea icon-sm icons"></i-feather>
                      <input type="text" class="form-control ps-5" placeholder="lotnisko Okęcie" name="s" required> -->
                      <div class="dropdown col-12">
                        <i-feather name="map-pin" class="fea icon-sm icons"></i-feather>
                        <input class="form-select form-control ps-5" [(ngModel)]="Value" name="s" value="Value" placeholder="lotnisko Okęcie">
                        <div class="dropdown-content">
                          <div *ngFor="let item of aiportList">
                            <a class=" ps-5" href="#" (click)="open(item.name, item.link)">{{item.name}}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Od kiedy do kiedy </label>
                    <input name="date" type="text" class="form-control start" placeholder="Zaznacz datę :" mode="range"
                      [(ngModel)]="modelData" mwlFlatpickr [minDate]="minDate" [convertModelValue]="true" (ngModelChange)="sss()">
                  </div>
                </div>
                <!--end col-->
                <div class="col-md-12">
                  <div class="d-grid">
                    <button class="btn btn-primary" routerLink="/{{Route}}">Wyszukaj teraz:</button>
                  </div>
                </div>
              </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Benefity dla podróżujących</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Rezerwując miejsce parkingowe za pośrednictwem naszej strony internetowej, masz pewność, że miejsce będzie dla Ciebie dostępne o wyznaczonej porze. Nie trzeba martwić się o brak miejsca w momencie przyjazdu. Rezerwując z wyprzedzeniem, możesz również skorzystać z atrakcyjnych cen specjalnych i promocji. Dodatkowo, nasza strona oferuje wygodne opcje płatności online. Nie czekaj, zarezerwuj swoje miejsce już teraz!</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-shield-check"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Bezpieczna Płatność</h5>
            <p class="para text-muted mb-0">Twoje dane są szyfrowane przez https!</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-thumbs-up"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Łatwa rezerwacja</h5>
            <p class="para text-muted mb-0">Przyjazny interfejs dla każdego użytkownika</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-award"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Najlepsze oferty</h5>
            <p class="para text-muted mb-0">Najlepsze możliwe ceny dla naszych klientów</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-favorite"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Najlepiej oceniane</h5>
            <p class="para text-muted mb-0">Nasi partnerzy mają najlepsze opinie!</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
<!-- 
  <div class="container mt-100 mt-60">
    <div class="row align-items-end mb-4 pb-2">
      <div class="col-md-8">
        <div class="section-title text-md-left">
          <h4 class="title mb-4">Najbardziej poluarne lotniska:</h4>
          <p class="text-muted mb-0 para-desc">Klikając na dowolną miejscowość, możesz przenieść się i zobaczyć jakie ceny parkingu lotniczego przygotowaliśmy dla ciebie na tygodniowy wakacje:</p>
        </div>
      </div>

    </div>

  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mt-4 pt-2">
        <owl-carousel-o id="six-slide" [options]="customOptions" class="owl-stage-outer">
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/dubai.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">Katowice</a>
              </div>
            </div>

          </ng-template>
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/thailand.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark"></a>
              </div>
            </div>

          </ng-template>
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/india.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">India</a>
              </div>
            </div>

          </ng-template>
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/london.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">Londan</a>
              </div>
            </div>

          </ng-template>
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/paris.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">Paris</a>
              </div>
            </div>

          </ng-template>
          <ng-template carouselSlide>
            <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
              <img src="assets/images/travel/singapore.jpg" class="img-fluid" alt="">
              <div class="overlay-work bg-dark"></div>
              <div class="content">
                <a href="javascript:void(0)" class="title text-white h4 title-dark">Singapore</a>
              </div>
            </div>

          </ng-template>
        </owl-carousel-o>

      </div>

    </div>

  </div> -->
</section>

<section class="section bg-light">
  <!-- <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title fw-bold mb-4">Co powinneś wiedzieć przed pierwszy wylotem na wakacje</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Przygotowaliśmy krótkie poradniki dla naszych kilentów, którę pomogą łatwiej poruszać się na lotniskach. Spędzić najlepsze wakacje, bez nerwów.</p>
        </div>
      </div>
    </div>
  </div> -->
<!-- <div>
<h5 class="text-center">during implementation</h5>
</div> -->
  <!-- <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6">
        <div class="card blog rounded border-0 shadow overflow-hidden">
          <div class="position-relative">
            <img src="assets/images/travel/1.jpg" class="card-img-top" alt="...">
            <div class="overlay rounded-top bg-dark"></div>
          </div>
          <div class="card-body content">
            <h5><a href="javascript:void(0)" class="card-title title text-dark">Conversations, Feedback, Recognition</a>
            </h5>
            <div class="post-meta d-flex justify-content-between mt-3">
              <ul class="list-unstyled mb-0">
                <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i
                      class="uil uil-heart me-1"></i>33</a></li>
                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                      class="uil uil-comment me-1"></i>08</a></li>
              </ul>
              <a routerLink="/page-blog-detail" class="text-muted readmore">Read More <i
                  class="uil uil-angle-right-b align-middle"></i></a>
            </div>
          </div>
          <div class="author">
            <small class="text-light user d-block"><i class="uil uil-user"></i> Calvin Carlo</small>
            <small class="text-light date"><i class="uil uil-calendar-alt"></i> 13th August, 2019</small>
          </div>
        </div>
      </div>


      <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4">Experience Luxury & <br> Find Your Base</h4>
          <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
            from the Application theme and use it in the Website.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="mt-3 h6 text-primary">Search Destination <i
              class="uil uil-angle-right-b"></i></a>
        </div>
      </div>
    </div>
  </div> -->
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 order-1 order-md-2">
        <img src="assets/images/travel/3.jpg" class="img-fluid rounded shadow-md" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title me-lg-5">
          <h4 class="title mb-4">Najcześciej zadawane pytania</h4>
        </div>

        <div class="faq-content mt-4 pt-2">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0" id="headingtwo">
                  <h6 class="title mb-0 text-dark">Jak to działa? 
                  </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">Rezerwujesz parking, dokonujesz płatności. Na parkingu otrzymujesz bilet potwierdzający pozostawienie auta. W zależności od partnera i odległości od lotniska zostaniesz przewieziony pod terminal. Po odebraniu bagaży dzwonisz na podany w potwierdzeniu rezerwacji numer telefonu. Pracownik parkingu odbiera cię z lotniska, a następnie możesz ruszać w drogę!</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 " id="headingtwo">
                  <h6 class="title mb-0 text-dark"> Czy mogę anulować swoją rezerwacje? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">Tak, każdą rezerwację można u nas anulować do 24 godzin przed datą przyjazdu na parking. Dlaczego 24 godziny? Ze względu na to, że w trakcie sezonu parkingi oddalone od lotniska mogą nie znaleźć już klientów na to miejsce.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0" id="headingtwo">
                  <h6 class="title mb-0 text-dark">Czy zmina daty przyjazdu i powrótu jest dodatkowo płatna?</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">Nie zmieniamy dnia twojego przyjazdu ani powrotu - jest to w 100% darmowe, jeśli czas parkowania twojego auta jest dokładnie taki sam. Proszę jednak pamiętać, że zmiany muszą zostać dokonane do 24 godzin przed pierwszą datą rezerwacji.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0" id="headingtwo">
                  <h6 class="title mb-0 text-dark">Jestem właściclem parkingu i chciałbym dołaczyć do programu partneskiego. </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">Zapraszamy do kontaktu telefonicznego z obsługą lub przez e-mail.</p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>

        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

 

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Opinie naszych klientów:</h4>
          <p class="text-muted para-desc mx-auto mb-0">To tylko kilka z nich. Więcej opinii znajdziesz w opisach parkingów, które współpracują z nami.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
      </div>
      <!--end col-->
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
