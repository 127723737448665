import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { v4 as uuid } from 'uuid';
import { DiscountModel } from '../../Model/DiscountModel/DiscountModel';

@Injectable({
  providedIn: 'root'
})
export class DiscountServicesService {

  constructor(private http: HttpClient) { }

  discountModel :DiscountModel;

  conetingString :string = environment.api_url + "Discount/GetDiscount";
  guid =uuid.v4()

  headers= new HttpHeaders()
  .set('Guid', this.guid)


  GetDiscount(option:DiscountModel) : Observable<DiscountResponse> {
    let params = new HttpParams();
    params = params.append('Code',option.Code.toString());
    params = params.append('OffertId',option.Id);


    return this.http.get<DiscountResponse>(this.conetingString,{ 'headers': this.headers ,'params':params}) /* context: HttpContextToken('test') */
    
  }

}


export interface DiscountResponse{
  id?:number
  methodOfAccounting?:boolean
  value?:number
  status: string
  success: boolean
  message?:string,
  validationErrors?:Array<string>

}
