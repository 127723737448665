import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parking-regulamin',
  templateUrl: './parking-regulamin.component.html'
})
export class ParkingRegulaminComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private http: HttpClient) { }

  parkingURL:string='';
  reqestLink:string=environment.api_url + 'ParkingInstance/GetParkingRules/'

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const parametrValue = params['ParkingURL'];
      this.parkingURL = parametrValue;
      this.reqestLink+=params['ParkingURL'];
      this.getHtmlString();
    });
  }
  htmlString: string;

  getHtmlString() {
    this.http.get(this.reqestLink).subscribe(
      (response:any) => {
        this.htmlString = response.parkingRules;
        console.log(response)
      },
      (error) => {
        console.error('Wystąpił błąd:', error);
      }
    );
  }

}
