import { Injectable } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { dataModel, dataPars, ModelCalculate } from '../../Model/DataModel/dataModelService';



@Injectable({
  providedIn: 'root'
})
export class GlobalDateDataService {

  constructor() { }
  private CheckIn: Date = undefined;

  private price = new Subject<number>();
  private selectedAirport = new Subject<string>();
  message$: Observable<string> = this.selectedAirport.asObservable();

  dataModel :dataModel ={
    checkIn: this.StartData(new Date(new Date().getTime())),    
    hourIn :"12:00",
    checkOut: this.StartData(new Date(new Date().getTime() + 24 * 60 * 60 * 1000*7)),
    hourOut:"15:00"
  }
  dataPars : dataPars={
    checkIn: new Date(),
    checkOut: new Date()
  };
  ModelCalculate : ModelCalculate ={
    priceMethod: false,
    priceList: [100,110,120,130,140,150,160,170],
    eachSubsequentDay: 10
  }
  
  StartData(dateParam:Date){
    let date = new Date(dateParam.getTime());
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(1, '0');
    let day = date.getDate().toString().padStart(1, '0');
    let formattedDate = `${year}-${month}-${day}`;
    let dateObject = new Date(formattedDate);

    return dateObject
  }
  UpdateSelectedAirport(aiport:string){
    this.selectedAirport.next(aiport);
  }

  UpdateCheckInView(date:Date){
    this.dataModel.checkIn=date
  }
  UpdateCheckOutView(date:Date){
    this.dataModel.checkOut=date
  }

  UpdateCheckIn(date:Date){
    this.dataModel.checkIn=date
  
    this.ShowParameter()
  }
  UpdateHourIn(hour:string){
    this.dataModel.hourIn=hour
    
    this.ShowParameter()
  }
  UpdateCheckOut(date:Date){
    this.dataModel.checkOut=date
   
    this.ShowParameter()
  }
  UpdateHourOut(hour:string){
    this.dataModel.hourOut=hour
    this.ShowParameter()
  }

  ShowParameter(){
    let resoult=this.CalculatePrica(this.ModelCalculate)

   this.price.next(resoult)
  }

  RentalPeriodPerDay(){
    let diffInMilliseconds = this.dataModel.checkOut.getTime() - this.dataModel.checkIn .getTime();
    let diffInDays = (diffInMilliseconds+(1000*60*60*24)) / (1000 * 60 * 60 * 24);
    let wholeNumber = Math.floor(diffInDays);
    return wholeNumber;
  }

  parseData(date:Date , hour :string ) :Date {
    let checkInSplitted = hour.split(":", 2); 
    date = new Date(date.getTime() + (1000 * 60 * 60 * parseInt(checkInSplitted[0])) +(1000*60*parseInt(checkInSplitted[1])))
    return date
  }

  RentalPeriodPerHour()
  {
    this.dataPars.checkIn =this.parseData(this.dataModel.checkIn,this.dataModel.hourIn);
    this.dataPars.checkOut =this.parseData(this.dataModel.checkOut,this.dataModel.hourOut);    
    let diffInMilliseconds = this.dataPars.checkOut.getTime() - this.dataPars.checkIn .getTime();
    let diffInDays = diffInMilliseconds / (1000 * 60 * 60 );
    let wholeNumber = Math.floor(diffInDays);
    console.log(wholeNumber)
    let numberOfHours=0;
    if(wholeNumber%24==0)
    {
      numberOfHours=wholeNumber/24
    }
    else
    {
      numberOfHours= Math.floor(wholeNumber/24)+1
    }
    return numberOfHours
  }
  

  CalculatePrica(ModelCalculate : ModelCalculate){
    let numberOfHours=0
    if(ModelCalculate.priceMethod===true)
    {
      numberOfHours=this.RentalPeriodPerDay()
    }
    else
    {
      numberOfHours=this.RentalPeriodPerHour()
    }
    if(ModelCalculate.priceList.length<numberOfHours)
    {
      let diffrent = (numberOfHours-ModelCalculate.priceList.length)*ModelCalculate.eachSubsequentDay
      return ModelCalculate.priceList[ModelCalculate.priceList.length-1]+ diffrent
    }
    else if(ModelCalculate.priceList.length==numberOfHours)
    {
      return ModelCalculate.priceList[ModelCalculate.priceList.length-1]
    }
    else{
      return ModelCalculate.priceList[numberOfHours]
    }
  }
  

  getPrice():Observable<number>{
    return this.price.asObservable()
  }

  GetDataObj():Observable<dataModel>{
    return of(this.dataModel)
  }
}
