import { CookieService } from 'ngx-cookie-service';
import { GlobalDateDataService } from './core/services/globalDateData/global-date-data.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { LightboxModule } from 'ngx-lightbox';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CountToModule } from 'angular-count-to';
import { NgxMasonryModule } from 'ngx-masonry';

import { SimplebarAngularModule } from 'simplebar-angular';


import { SharedModule } from "./shared/shared.module";

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { AccountMembersComponent } from './core/components/account-members/account-members.component';
import { AccountMessagesComponent } from './core/components/account-messages/account-messages.component';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { MasterPageComponent } from './core/used/master-page/master-page.component';

import { AuthCoverLoginComponent } from './auth/auth-cover-login/auth-cover-login.component';
import { AuthCoverRePasswordComponent } from './auth/auth-cover-re-password/auth-cover-re-password.component';
import { AuthCoverSignupComponent } from './auth/auth-cover-signup/auth-cover-signup.component';

import { PageComingsoonComponent } from './core/components/page-comingsoon/page-comingsoon.component';
import { PageErrorComponent } from './core/components/page-error/page-error.component';
import { PageMaintenanceComponent } from './core/components/page-maintenance/page-maintenance.component';

import { WidgetComponent } from './core/components/widget/widget.component';
import { FeatherModule } from 'angular-feather';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { allIcons } from 'angular-feather/icons';

import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';

import flatpickr from 'flatpickr';
import { Polish } from 'flatpickr/dist/l10n/pl';
import { BookingFormComponent } from './core/used/booking-form/booking-form.component';
import { ListSearchComponent } from './core/used/list-search/list-search.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { GetAiportListService } from './core/services/mainpage/get-aiport-list-service';
import { ParkListApiService } from './core/services/park-list-api/park-list-api.service';
import { OrderService } from './core/services/Order/order.service';

import { IndexComponent } from './core/used/index/index.component';
import { ThanksForOrderComponent } from './core/used/thanks-for-order/thanks-for-order.component';
import { StarRatingComponent } from './shared/star-rating/star-rating.component';
import { StickyPanelCookiesComponent } from './shared/sticky-panel-cookies/sticky-panel-cookies.component';
import { ParkingRegulaminComponent } from './shared/parking-regulamin/parking-regulamin.component';
import { SideRegulaminComponent } from './core/used/side-regulamin/side-regulamin.component';
import { PagePrivacyComponent } from './core/used/page-privacy/page-privacy.component';
import { PageContactComponent } from './core/used/page-contact/page-contact.component';
import { PageAboutusComponent } from './core/used/page-aboutus/page-aboutus.component';
import { ParkingViewComponent } from './component/ParkingView/parking-view/parking-view.component';
import { ParkingViewModule } from './component/ParkingView/parking-view.module';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AccountMembersComponent,
    AccountMessagesComponent,
    LoginComponent,
    RegistrationComponent,
    MasterPageComponent,
    AuthCoverLoginComponent,
    AuthCoverRePasswordComponent,
    AuthCoverSignupComponent,
    PageComingsoonComponent,
    PageErrorComponent,
    PageMaintenanceComponent,
    WidgetComponent,
    BookingFormComponent,
    ListSearchComponent,
    IndexComponent,
    ThanksForOrderComponent,
    StickyPanelCookiesComponent,
    PagePrivacyComponent,
    ParkingRegulaminComponent,
    SideRegulaminComponent,  
    PageContactComponent,
    PageAboutusComponent,
    StarRatingComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    CarouselModule,
    FeatherModule.pick(allIcons),
    ScrollToModule.forRoot(),
    RouterModule.forRoot([], {}),
    NgxYoutubePlayerModule,
    NgbDropdownModule,
    CKEditorModule,
    NgbModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUsefulSwiperModule,
    NgxTypedJsModule,
    FlatpickrModule.forRoot(),
    CountToModule,
    NgxMasonryModule,
    LightboxModule,
    SharedModule,
    SimplebarAngularModule,
    HttpClientModule,
    CommonModule,
   // NgxGoogleAnalyticsModule.forRoot('G-F6JRG43NV7'),
   // NgxGoogleAnalyticsRouterModule
   // ParkingViewModule,
  ],
  exports: [
    FeatherModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    GetAiportListService,
    ParkListApiService,
    OrderService,
    GlobalDateDataService,
    DatePipe,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function flatpickrFactory() {
  flatpickr.localize(Polish);
  return flatpickr;
}