
import { Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { AccessModel } from '../../Model/AccessModel/AccessModel';

import { CheckStatusModel } from '../../Model/AccessModel/CheckStatusModel';
import { take,takeUntil } from 'rxjs/operators';
import Polish from 'flatpickr/dist/l10n/pl.js';
import flatpickr from "flatpickr"
import { flatpickrFactory } from 'src/app/app.module';
import { DiscountServicesService } from '../../services/DiscountS/discount-services.service';
import { GlobalDateDataService } from '../../services/globalDateData/global-date-data.service';
import { CheckPriceService } from '../../services/CheckPrice/check-price.service';
import { OrderService } from '../../services/Order/order.service';
import { dataModel, dataPars } from '../../Model/DataModel/dataModelComponent';
import { DiscountModel } from '../../Model/DiscountModel/DiscountModel';
import { RepoDate } from '../../Model/AccessModel/RepoDate';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AccesService } from '../../services/Account/acces.service';
import { RequestAccessModel } from '../../Model/AccessModel/RequestAccessModel';
import { Meta, Title } from '@angular/platform-browser';
import { DataLayerService } from '../../services/WindowReferenceService/DataLayerService';


@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.css'],
  providers:[DiscountServicesService,AccesService]
})

export class BookingFormComponent implements OnInit {

  constructor(private GlobalData : GlobalDateDataService,
              private CheckPrice: CheckPriceService,
              private OrderServices :OrderService,
              private chceckCupon: DiscountServicesService,
              private router: Router,
              private route: ActivatedRoute,
              private datePipe: DatePipe,
              private accesService: AccesService,
              private meta: Meta,
              private titleService:Title
              ) 
              {
                this.titleService.setTitle(this.title);
              }

    usernamePattern = /^[a-zA-Z0-9ąćęłńóśźżĄĘŁŃÓŚŹŻ ]+$/;
    userEmailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/;
    onlyNumbersPattern =/^[0-9]+$/;
    addressPattern= /^[a-zA-Z0-9ąćęłńóśźżĄĘŁŃÓŚŹŻ /\//]+$/;
    postalCodePattern =/^[0-9]{2}-[0-9]{3}/;
    
    nipPattern=/^[0-9]{10}/;
    blikPattern: RegExp=/^\d{6}$/;

    showdecsc:boolean=false;

    title = 'Fromularz rezerwacyjny parkingu';

    // @Input() formData!: AccessModel;

    ParkingName?:string



    
    //Walidacja danych
    vModel:ValidateUserModel={
      FirstName:false,
      LastName:false,
      NumberPhone:false,
      Email:false,
      carRegistration :[{car:false},{car:false},{car:false},{car:false},{car:false}],
      rules:false,
      NameCopany:false,
      Nip:false,
      Address:false,
      PostalCode:false,
      City:false,
      Blik:false
    }
    
    checkboxTouched:boolean=false;
    
    // onBooleanFieldChange<T extends keyof typeof this.vModel>(field: T, value: boolean ): void {
      //   this.vModel[field] = value !== true ? !value : false;
      //   this.checkValideData();
      // }
      
      onBFirstName(FirstName: boolean|null){
        this.vModel.FirstName=!FirstName
        this.checkValideData()
      }
      onBLastName(LastName:  boolean|null){
        this.vModel.LastName=!LastName
        this.checkValideData()
      }
    onBNumberPhone(NumberPhone:  boolean|null){
      this.vModel.NumberPhone=!NumberPhone
      this.checkValideData()
    }
    onBEmail(Email:  boolean|null){
      this.vModel.Email=!Email
      this.checkValideData()
    }
    onBRules(){
      this.vModel.rules=!this.vModel.rules
      this.checkboxTouched = true;
      this.checkValideData()
    }
    onBNameCopany(NameCopany:  boolean|null){
      this.vModel.NameCopany=!NameCopany
      this.checkValideData()
    }
    onBNip(Nip:  boolean|null){
      this.vModel.Nip=!Nip
      this.checkValideData()
    }
    onBAddress(Address:  boolean|null){
      this.vModel.Address=!Address
      this.checkValideData()
    }
    onBPostalCode(PostalCode:  boolean|null){
      this.vModel.PostalCode=!PostalCode
      this.checkValideData()
    }
    onBCity(City:  boolean|null){
      this.vModel.City=!City
      this.checkValideData()
    }
    onBRefesh(){
      this.checkValideData()
    }
    
    onBCars(Cars :  boolean|null,id: number){
      this.vModel.carRegistration[id].car=!Cars;
      this.checkValideData()
    }
    onBBlick(blik :boolean|null){
      this.vModel.Blik=!blik
      this.checkValideData()
    }
    
    
    checkValideData(){
      //Dane podróżującego :
      let v = this.vModel;
      if(v.Email && v.FirstName && v.LastName && v.NumberPhone && v.rules){
        let allCarsCoret= true
        for(let i =0; i<this.quantityOfCars;i++){
          if(v.carRegistration[i].car==false){
            allCarsCoret=false
          }
        }
        if(allCarsCoret){
          //Dane Do faktury
          if(this.OrderData.wantAnInvoice){
            if(v.City && v.Address && v.NameCopany && v.Nip && v.PostalCode){
              if(this.OrderData.PaymentMedia.Name =="Blik"){
                if(this.blikPattern.test(this.OrderData.PaymentMedia.Args[0])){
                    this.buttonAllow=false;
                  }
                  else{
                      this.buttonAllow=true;
              }
            }
            else
              this.buttonAllow=false;

            }
            else{
              this.buttonAllow=true;
            }
            // <--- metoda walidacji faktury
          }
          else{
            if(this.OrderData.PaymentMedia.Name =="Blik"){
              if(this.blikPattern.test(this.OrderData.PaymentMedia.Args[0])){
                  this.buttonAllow=false;
                }
                else{
                    this.buttonAllow=true;
            }
          }
          else{
            this.buttonAllow=false;
          }
          }
        }
        else{
          this.buttonAllow=true;
        }

      }
      else{
        this.buttonAllow=true;
      }
    }

    SetDateTime(){

    }
            
    now = new Date();
    Today: Date = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate(), 0, 0, 0)

    dataModel :dataModel ={
      checkIn: new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate(), 0, 0, 0), //this.StartData(new Date(new Date().getTime()+1000*60*60)),
      hourIn :"11:00",
      checkOut: new Date(this.Today.getTime() + 24 * 60 * 60 * 1000*7),
      hourOut:"12:00"
    }
    
    sss:string= "text-primary"
    ssss:string=""
    name:string=''
    OldPrice:number=0;
    NewPrice:number=0;
    price:number=0
    CzyJestRabat:boolean=false;
    DiscountView: number=0;

    ChceckCupon($event :any){
      this.discountModel.Id =1;
      this.discountModel.Code=this.OrderData.Discount
      let valeOfDiscound:any = 0;
      this.chceckCupon.GetDiscount(this.discountModel).subscribe( data =>{
        if(data!=null){
        valeOfDiscound = data.value
        this.NewPrice=this.OldPrice-valeOfDiscound
        this.DiscountView= valeOfDiscound
        this.CzyJestRabat=true;
        this.ssss="text-decoration: line-through"
        this.buttonAllow=false
        }
      })
    }
    discountModel: DiscountModel={
      Id:0,
      Code:''
    }


    doSomethingElse(usernameInput: string) {
      if(usernameInput){
        this.buttonAllow=true;
      }
      else
      {
        this.buttonAllow=false;
      }
    }

    minDate = new Date().getTime()-1000*60;

    dataPars : dataPars={
      checkIn: new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate(), 0, 0, 0),
      checkOut: new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate(), 0, 0, 0)
    };
    quantityOfCars:number=1;
    myUrl:string='';


  ngOnInit(): void {
    this.meta.updateTag({ name: 'description', content: 'Zaplanuj i zarezerwuj swoje miejsce parkingowe online! Skorzystaj z naszego wygodnego formularza rezerwacyjnego i ciesz się bezstresowym parkowaniem. Nasz formularz rezerwacyjny oferuje łatwe i szybkie rezerwacje, eliminując konieczność dzwonienia czy osobistego kontaktu. Znajdź idealne miejsce parkingowe dla swojego samochodu - rezerwuj już teraz!'});

    this.route.queryParams.subscribe(params => {
      
      let checkInParam = this.SetData(params['rozpoczecie'])
      let checkOutParam = this.SetData(params['zakonczenie'])

      this.myUrl='regulamin_parkingu/'+  params['Nazwa']


      this.dataModel.checkIn =checkInParam.date
      this.dataModel.checkOut =checkOutParam.date
      this.dataModel.hourIn= checkInParam.hour
      this.dataModel.hourOut= checkOutParam.hour
      this.CheckPrice.UpdateCheckIn(checkInParam.date);
      this.CheckPrice.UpdateCheckOut(checkOutParam.date); 

      //Set Time
     let parkingName = params['Nazwa']

      // Tutaj możesz wykonać odpowiednie działania na podstawie odczytanego parametru
      let accessModel:RequestAccessModel = {
        Guid: '',
        Origin: '',
        CheckIn: this.datePipe.transform( checkInParam.date, 'MM-dd-YYYY'),
        CheckOut: this.datePipe.transform( checkOutParam.date, 'MM-dd-YYYY'),
        ParkingName: parkingName,
        OferrtName: params['Oferta']
      };
      this.meta.updateTag({ name: 'name', content: 'Formularz rezerwacyjny parkingu ' +parkingName})
      this.title = "Formularz rezerwacyjny " + parkingName
  
      this.accesService.getAccess(accessModel).subscribe(data => {
  
        this.formData =data;
        this.CheckPrice.UpdateOffertGuid(data.formData.availableOffers[0].guid);
        this.OrderData.OrderInfo.ParkingInstanceCode=data.formData.parkingInstanceCode;
        this.OrderData.OrderInfo.OffertCode=data.formData.availableOffers[0].guid;
        this.onProfitSelectionChange(data.formData.paymentMethods[0],0);
        flatpickrFactory();
        this.CheckPrice.UpdateCheckIn(this.dataModel.checkIn);
        this.CheckPrice.UpdateCheckOut(this.dataModel.checkOut);
        this.CalculatePrice();
        this.CountDay();
      })
    });
  }

  SetData(date:string):DataParsObject{
    let value = date.split("H");
    let paramOne =new Date(new Date(value[0]).getTime()-2000*60*60)
    if(value.length==2)
      return {date: paramOne, hour: value[1]}
    return {date: paramOne, hour: "12:00"}
  }

  formData: any;

  //Poprawne liczenie miejsc i wyświetlanie pól do wpisania rejestracji

  carss: Array<Cars>=[{car:""}]

  AddCars($event:any){
    if(this.carss.length>this.quantityOfCars){
      let oldCars=this.carss
      this.carss=new Array<Cars>()
      for(let h = 0; h < this.quantityOfCars; h++){
        this.carss.push(oldCars[h])
      }
    }
    else{
      let oldCars=this.carss
      this.carss=new Array<Cars>()
      for(let h = 0; h < oldCars.length; h++){
        this.carss.push(oldCars[h])
      }
      for(let j = 0; j < this.quantityOfCars-oldCars.length; j++){
        let newCar :Cars={car:""};
        this.carss.push(newCar)
      }
    }
    this.OrderData.carRegistration = this.carss;
    this.OrderData.Cars= this.quantityOfCars;
    this.CheckPrice.UpdateCars(this.quantityOfCars)
    this.CalculatePrice()

    this.checkValideData()
  }

  CalclulatePriceStart(){
    this.OldPrice=this.price*this.quantityOfCars
  }
  PlaceMessage:string=""
  CalculatePrice(){
    this.CheckPrice.getPrice().subscribe(data => {
      this.price = data.price/ this.quantityOfCars
      this.OldPrice = data.price
      this.CanYouBooK=data.status
      this.PlaceMessage = data.message
    });
  }

  CalclulatePrice($event:any){
    this.OldPrice=this.price*this.quantityOfCars
  }

  UpdateCheckIn($event:any){
    this.CheckPrice.UpdateCheckIn(this.dataModel.checkIn)
    this.CalculatePrice()
    this.CountDay()
  }
  UpdateHourIn($event:any){
    this.CheckPrice.UpdateHourIn(this.dataModel.hourIn)
    this.OrderData.data.hourIn = this.dataModel.hourIn
  }
  UpdateCheckOut($event:any){
    this.CheckPrice.UpdateCheckOut(this.dataModel.checkOut)
    this.CalculatePrice()
    this.CountDay()
  }
  UpdateHourOut($event:any){
    this.CheckPrice.UpdateHourOut(this.dataModel.hourOut)
    this.OrderData.data.hourOut = this.dataModel.hourOut
  }

  paymentmethod!:number;

  onProfitSelectionChange(method:RepoDate, id:number): void {
    this.OrderData.PaymentMedia.Id = id;
    this.OrderData.PaymentMedia.Guid= method.guid;
    this.OrderData.PaymentMedia.Name= this.formData.formData.paymentMethods[id].paymentSettingsName;
    this.selectedMOP =this.formData.formData.paymentMethods[id].paymentSettingsName;
    this.checkValideData()
  }
  CountDay(){
    let year1 = this.dataModel.checkOut.getFullYear();
    let month1 = ('0' + (this.dataModel.checkOut.getMonth() + 1)).slice(-2);
    let day1 = ('0' + this.dataModel.checkOut.getDate()).slice(-2);
    let checkOut= new Date(`${year1}-${month1}-${day1}`);
    this.OrderData.data.checkOut=`${year1}-${month1}-${day1}`;
    this.OrderData.data.hourOut = this.dataModel.hourOut

    let year = this.dataModel.checkIn.getFullYear();
    let month = ('0' + (this.dataModel.checkIn.getMonth() + 1)).slice(-2);
    let day = ('0' + this.dataModel.checkIn.getDate()).slice(-2);
    let checkIn=new Date(`${year}-${month}-${day}`);
    this.OrderData.data.checkIn=`${year}-${month}-${day}`;
    this.OrderData.data.hourIn = this.dataModel.hourIn

    let time =(checkOut.getTime()-checkIn.getTime())/24/60/60/1000

    this.diffInDays = time+1;
  }
  diffInDays:number=8;

  buttonAllow:boolean=true;

  forbiddenName:string ='sss';

  selectedMOP:string='';

  Invoice:Invoice={
    NameCopany:'',
    Country:'',
    Nip:'',
    Address:'',
    PostalCode:'',
    City:'',
  }
  AnswerFromBackend:any;
  CanYouBooK:boolean=true;

  errorComuncate :string ='';
  @Output() OrderStatus = new EventEmitter<boolean>();

   stopInterval$ = new Subject();
   linkWebsite:string ='';

  send(){
    this.OrderServices.PostOrder(this.OrderData).subscribe(
      data => {
        this.AnswerFromBackend = data
       

        if(false ==data.success){
          this.errorComuncate='Niestety ale w tym teminie zostały zarezerwoane wszystkiemiejsca'
        }
        else
        {
          if(this.selectedMOP =="Cash"){
            this.router.navigate(['/successful_creation_of_a_reservation', data.id])}
          else if(this.selectedMOP == "Blik"){
            let CheckStatusModel : CheckStatusModel={
              NumerOrder: data.orderData.numerOrder,
              OrderId: data.orderData.orderId,
              PaymentMethodGuid: this.OrderData.PaymentMedia.Guid,
              ParkingInstanceGuid: this.OrderData.OrderInfo.ParkingInstanceCode,
            }
            const element =document.getElementById('PayNowStoped');
            if (element) {
              element.style.display = 'block';
            }
            interval(10000).pipe(
              takeUntil(this.stopInterval$)).pipe(
              take(10))
              .subscribe(()=>
            {
              this.OrderServices.ChceckStatusOrder(CheckStatusModel).subscribe(
                status => {
                if(status.status =="CONFIRMED"){
                  if (element) {
                    element.style.display = 'none';
                  }
                  this.OrderStatus.emit(true)
                  this.stopInterval$.next(0);
                  this.stopInterval$.complete();
                  this.router.navigate(['/successful_creation_of_a_reservation', data.id])
                }
              })
            })
            
          }
          else if(this.selectedMOP == "PayU"){
            window.open(data.orderData.link, '_blank')
            this.linkWebsite=data.orderData.link

            let CheckStatusModel : CheckStatusModel={
              NumerOrder: data.orderData.numerOrder,
              OrderId: data.orderData.orderId,
              PaymentMethodGuid: this.OrderData.PaymentMedia.Guid,
              ParkingInstanceGuid: this.OrderData.OrderInfo.ParkingInstanceCode,
            }
            const element =document.getElementById('overlay');
            if (element) {
              element.style.display = 'block';
            }
            interval(10000).pipe(
              takeUntil(this.stopInterval$)).pipe(
              take(10))
              .subscribe(()=>
            {
              this.OrderServices.ChceckStatusOrder(CheckStatusModel).subscribe(
                status => {
                if(status.status =="COMPLETED"){
                  if (element) {
                    element.style.display = 'none';
                  }
                  this.OrderStatus.emit(true)
                  this.stopInterval$.next(0);
                  this.stopInterval$.complete();
                  this.router.navigate(['/successful_creation_of_a_reservation', data.id])
                }
              })
            })
          }
          else{
            this.errorComuncate='Wystąpił nieczokiwany błąd prosmy o rzerwacje pod numerem '
          }
        }
      },
      error => {
        // Obsługa błędu
        console.error('Błąd:', error);
        if(error!=null){
          this.errorComuncate='Wystąpił nieoczkiwany błąd serwera, prosimy o rezerwacje póżniej lub pod numerem 505 48 95 95.'
        }
      }
    )
  }
  burrrron(){
    this.router.navigate(['/successful_creation_of_a_reservation', 'dd'])
  }


  OrderData:Order={
    data: {
      checkIn:'',
      hourIn:'',
      checkOut: '',
      hourOut:''
    },
    Person:{
      FirstName:'',
      LastName:'',
      NumberPhone:'',
      Email:'',
      CreateAccount:false,
      Newsletter:false,
      acceptRools:false
    },
    Adult:1,
    Chaild:0,
    Cars:1,
    carRegistration:this.carss,
    Comment: '',
    wantAnInvoice:false,
    Invoice:this.Invoice,
    PaymentMedia:{
      Id:1,
      Guid: '',
      Name: '',
      Args: ['']
    },
    Discount:'',
    OrderInfo:{
      ParkingInstanceCode:'',
      OffertCode:' '
    }
  }


}
export interface ValidateUserModel{
  FirstName:boolean
  LastName:boolean
  NumberPhone:boolean
  Email:boolean
  carRegistration :Array<ValidateCarsModel>
  rules:boolean
  NameCopany:boolean
  Nip:boolean
  Address:boolean
  PostalCode:boolean
  City:boolean
  Blik:boolean
}
export interface ValidateCarsModel{
  car:boolean
}

export interface ModelCalculate{
  priceMethod: boolean
  priceList:Array<number>
  eachSubsequentDay:number
}

export interface Cars{
  car:string
}
export interface Person{
  FirstName:string
  LastName:string
  NumberPhone:string
  Email:string
  CreateAccount:boolean
  Newsletter:boolean
  acceptRools:boolean
}
export interface Invoice{
  NameCopany:string
  Country:string
  Nip:string
  Address:string
  PostalCode:string
  City:string
}


export interface Order{
  data: dataModelToOrder
  Person:Person
  Adult:number
  Chaild:number
  Cars:number
  wantAnInvoice:boolean
  carRegistration :Array<Cars>
  Comment: string
  Invoice:Invoice
  PaymentMedia:PaymentMedia
  Discount:string
  OrderInfo:OrderInfo
}

export interface PaymentMedia{
  Id:Number
  Guid:string
  Name:string
  Args:Array<string>
}

function ViewChild(arg0: string) {
  throw new Error('Function not implemented.');
}


export interface dataModelToOrder{
  checkIn: string
  hourIn : string
  checkOut: string
  hourOut: string
}

export interface OrderInfo{
  ParkingInstanceCode:string,
  OffertCode:string
}

export interface DataParsObject{
  date:Date,
  hour:string
}