<!-- Start -->
<div *ngIf="formData">

  <div id="overlay">
    <div class="comunicateStyle">
      <div class="comunicateEmelemnt">
        <h1>Trwa przetwarzanie płatnosci</h1>
        <a href="{{linkWebsite}}" target="_blank">
          Jeżeli nie zostałeś przeniosny na strone płatności kilkinj tutaj</a>
        </div>
      </div>
    </div>
    <div id="PayNowStoped">
        <div class="d-flex justify-content-center" style="padding-top: 100px;"> 
          <span class="sr-only" style="text-align: center; color: white; font-size: 35px;">Trwa przetwarzanie płatnosci...</span>
        </div>
        <div class="d-flex justify-content-center" style="padding-top: 100px;">
          <div class="spinner-border text-primary" style="width: 5rem; height: 5rem;" role="status">
          </div>
        </div>
      </div>
    
    
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-7  col-md-6 ">
            <div class="rounded shadow-md p-4 mb-4">
              <h5 class="mb-0">Termin rezerwacji :</h5>
              <div class="mt-1">
                <div class="row">
                  <div class="col-6">
                    <div class="mb-3">
                      <label class="form-label">Data przybycia:<span class="text-danger">*</span></label>
                      <input class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true" [convertModelValue]="true" altFormat="d.m.Y" dateFormat="d-m-Y" 
                        [(ngModel)]="dataModel.checkIn" (ngModelChange)="UpdateCheckIn($event)" [minDate]="Today">
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label class="form-label">Godzina przybycia:<span class="text-danger">*</span></label>
                  <input type="time" min="0" autocomplete="off" id="adult" required="" [(ngModel)]="dataModel.hourIn"
                  (ngModelChange)="UpdateHourIn($event)" class="form-control" value="12">
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label class="form-label">Data wyjazdu:<span class="text-danger">*</span></label>
                  <input class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true" [convertModelValue]="true" altFormat="d.m.Y" dateFormat="d-m-Y" 
                        [(ngModel)]="dataModel.checkOut" (ngModelChange)="UpdateCheckOut($event)" [minDate]="dataModel.checkIn">
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label class="form-label">Godzina wyjazdu:<span class="text-danger">*</span></label>
                  <input type="time" min="0" autocomplete="off" id="adult" required="" [(ngModel)]="dataModel.hourOut"
                  (ngModelChange)="UpdateHourOut($event)" class="form-control" value="12">
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="rounded shadow-lg p-4">
          <h5 class="mb-0">Dane podróżującego :</h5>
          
          <!-- <form class="mt-4"> -->
            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label class="form-label">Twoje imie: <span class="text-danger">*</span></label>
                  <input id="firstname" type="text" class="form-control" placeholder="np. Adam"
                  [(ngModel)]="OrderData.Person.FirstName" #userFirstNameInput="ngModel" [pattern]="usernamePattern"
                  [ngClass]="{ 'invalid': userFirstNameInput.invalid && userFirstNameInput.touched }" required
                  (blur)="onBFirstName(userFirstNameInput.invalid)">
                  <div *ngIf="userFirstNameInput.invalid && userFirstNameInput.touched">
                    <p class="text-danger">Imię jest wymagane.</p>
                  </div>
                </div>
              </div>
              <!--end col-->
              <div class="col-6">
                <div class="mb-3">
                  <label class="form-label">Twoje nazwisko: <span class="text-danger">*</span></label>
                  <input id="lastname" type="text" class="form-control" placeholder="np. Nowak"
                  [(ngModel)]="OrderData.Person.LastName" #userLastNameInput="ngModel" [pattern]="usernamePattern"
                  [ngClass]="{ 'invalid': userLastNameInput.invalid && userLastNameInput.touched }" required
                  (blur)="onBLastName(userLastNameInput.invalid)">
                  <div *ngIf="userLastNameInput.invalid && userLastNameInput.touched">
                    <p class="text-danger">Nazwisko jest wymagane.</p>
                  </div>
                </div>
              </div>
              <!--end col-->
              <div class="col-6">
                <div class="mb-3">
                  <label class="form-label">Numer telefonu: <span class="text-danger">*</span></label>
                  <input type="text" id="phone" class="form-control" placeholder="505489595"
                  [(ngModel)]="OrderData.Person.NumberPhone" #userPhoneInput="ngModel" [pattern]="onlyNumbersPattern"
                  [ngClass]="{ 'invalid': userPhoneInput.invalid && userPhoneInput.touched }" required
                  (blur)="onBNumberPhone(userPhoneInput.invalid)">
                  <div *ngIf="userPhoneInput.invalid && userPhoneInput.touched">
                    <p class="text-danger">Numer telefonu jest wymagany.</p>
                  </div>
                </div>
              </div>
              <!--end col-->
              <div class="col-6">
                <div class="mb-3">
                  <label class="form-label">E-mail <span class="text-danger">*</span></label>
                  <input id="email" type="email" class="form-control" placeholder="adam@gmail.com"
                  [(ngModel)]="OrderData.Person.Email" #userEmailInput="ngModel" [pattern]="userEmailPattern"
                  [ngClass]="{ 'invalid': userEmailInput.invalid && userEmailInput.touched }" required
                  (blur)="onBEmail(userEmailInput.invalid)">
                  <div *ngIf="userEmailInput.invalid && userEmailInput.touched">
                    <p class="text-danger">Email jest wymagany</p>
                  </div>
                </div>
              </div>
              <hr>
              <h5 class="mb-0 mt-1">Dane do obsługi:</h5>
              <div class="mt-4"></div>
            <div class="col-md-4 ">
              <div class="mb-3">
                <label class="form-label">Osoby dorosłe <span class="text-danger">*</span></label>
                <select class="form-select form-control" aria-label="Default select example"
                [(ngModel)]="OrderData.Adult">
                <option selected>1</option>
                <option vaule="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
          </div>
          
          <div class="col-md-4">
            <div class="mb-3">
              <label class="form-label">Dzieci <span class="text-danger">*</span></label>
              <select class="form-select form-control" aria-label="Default select example"
              [(ngModel)]="OrderData.Chaild">
              <option selected>0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label class="form-label">Samochody <span class="text-danger">*</span></label>
                <select class="form-select form-control" aria-label="Default select example"
                [(ngModel)]="quantityOfCars" (ngModelChange)="AddCars($event)">
                <option selected>1</option>
                <option value=2>2</option>
                <option value=3>3</option>
                <option value=4>4</option>
                <option value=5>5</option>
              </select>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-6 col-12" *ngFor="let s of carss;index as i">
              <div class="mb-3">
                <label class="form-label">Rejestracja {{i+1}}<span class="text-danger">*</span></label>
                <input type="text" id="carRegistration" class="form-control" placeholder="WX3S354" [(ngModel)]="s.car"
                #carsInput="ngModel" [pattern]="usernamePattern"
                [ngClass]="{ 'invalid': carsInput.invalid && carsInput.touched }" required
                (blur)="onBCars(carsInput.invalid,i)">
                <div *ngIf="carsInput.invalid && carsInput.touched">
                  <p class="text-danger">Rejestracja jest wymagana.</p>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
          <div class="col-12">
            <div class="mb-3">
              <label class="form-label text-primary" (click)="hideText = !hideText">Dodatkowe informacje <span class="text-muted">(Opcionalne)</span></label>
              <textarea  *ngIf="hideText" type="text" id="description" class="form-control" placeholder=""
              [(ngModel)]="OrderData.Comment" [maxLength]="200"></textarea>
            </div>
          </div>
          

          <div class="mb-3">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="invoice"
              [(ngModel)]="OrderData.wantAnInvoice" (change)="onBRefesh()">
              <label class="form-check-label" for="invoice">Chcę otrzymać fakturę VAT</label>
            </div>
          </div>
          
          <div class="row" *ngIf="OrderData.wantAnInvoice">
            <div class="col-6">
              <div class="mb-3">
                <label class="form-label">Nazwa firmy: <span class="text-danger">*</span></label>
                <input id="CompayName" type="text" class="form-control" placeholder="np. Paweł Nowak MED"
                [(ngModel)]="OrderData.Invoice.NameCopany" #userNameCopanyInput="ngModel"
                [pattern]="usernamePattern"
                [ngClass]="{ 'invalid': userNameCopanyInput.invalid && userNameCopanyInput.touched }" required
                (blur)="onBNameCopany(userNameCopanyInput.invalid)">
                <div *ngIf="userNameCopanyInput.invalid && userNameCopanyInput.touched">
                  <p class="text-danger">Nazwa firmy jest wymagane.</p>
                </div>
              </div>
            </div>
            <!--end col-->
            <div class="col-6">
              <div class="mb-3">
                <label class="form-label">Nip: <span class="text-danger">*</span></label>
                <input id="Nip" type="text" class="form-control" placeholder="np. 8328723434"
                [(ngModel)]="OrderData.Invoice.Nip" [maxLength]="10" #useronNipInput="ngModel"
                [pattern]="nipPattern" [ngClass]="{ 'invalid': useronNipInput.invalid && useronNipInput.touched }"
                required (blur)="onBNip(useronNipInput.invalid)">
                <div *ngIf="useronNipInput.invalid && useronNipInput.touched">
                  <p class="text-danger">NIP jest wymagane.</p>
                </div>
              </div>
            </div>
              <div class="col-4">
                <div class="mb-3">
                  <label class="form-label">Adres: <span class="text-danger">*</span></label>
                  <input id="Streat" type="text" class="form-control" placeholder="np. Towarowa 7"
                  [(ngModel)]="OrderData.Invoice.Address" #userAddressInput="ngModel" [pattern]="addressPattern"
                  [ngClass]="{ 'invalid': userAddressInput.invalid && userAddressInput.touched }" required
                  (blur)="onBAddress(userAddressInput.invalid)">
                  <div *ngIf="userAddressInput.invalid && userAddressInput.touched">
                    <p class="text-danger">Adres jest wymagane.</p>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="mb-3">
                  <label class="form-label">Kod pocztowy: <span class="text-danger">*</span></label>
                  <input id="PostalCode" type="text" class="form-control" placeholder="np. 42‑600"
                  [(ngModel)]="OrderData.Invoice.PostalCode" #userPostalCodeInput="ngModel"
                  [pattern]="postalCodePattern"
                  [ngClass]="{ 'invalid': userPostalCodeInput.invalid && userPostalCodeInput.touched }" required
                  (blur)="onBPostalCode(userPostalCodeInput.invalid)">
                  <div *ngIf="userPostalCodeInput.invalid && userPostalCodeInput.touched">
                    <p class="text-danger">Kod pocztowy jest wymagane.</p>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="mb-3">
                  <label class="form-label">Miejscowość: <span class="text-danger">*</span></label>
                  <input id="city" type="text" class="form-control" placeholder="np. Tarnowskie góry"
                  [(ngModel)]="OrderData.Invoice.City" #userCityInput="ngModel" [pattern]="usernamePattern"
                  [ngClass]="{ 'invalid': userCityInput.invalid && userCityInput.touched }" required
                  (blur)="onBCity(userCityInput.invalid)">
                  <div *ngIf="userCityInput.invalid && userCityInput.touched">
                    <p class="text-danger">Miejscowość jest wymagane.</p>
                  </div>
                </div>
              </div>
              
            </div>
            
            <div class="mb-3">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="regulamin"
                [(ngModel)]="OrderData.Person.acceptRools" (change)="onBRules()">
                <label class="form-check-label" for="regulamin">Zapoznałem się i akceptuje <a
                  href="/regulamin" target="_blank">regulamin rezerwacji</a><span
                  class="text-danger">*</span>, <a
                  [href]="myUrl" target="_blank">regulamin parkingu</a><span
                  class="text-danger">*</span></label>
                  <div *ngIf="checkboxTouched && vModel.rules==false">
                    <p class="text-danger">Akceptacja Regulaminu jest wymagana</p>
                  </div>
                </div>
              </div>
              <hr>
              
              <div class="mb-3">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="commercial"
                  [(ngModel)]="OrderData.Person.Newsletter">
                  <label class="form-check-label" for="commercial">Wyrażam zgodę na przesyłanie informacji handlowych na
                    podany adres poczty elektronicznej.</label>
                  </div>
                </div>
                
                <!-- <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="createaccount"
                    [(ngModel)]="OrderData.Person.CreateAccount">
                    <label class="form-check-label" for="createaccount">Chce założyć konto, aby móc rzadzać swoją historią
                      rezerwacji.</label>
                    </div>
                  </div> -->

                  
                  
                  
                  
                  
                  <!--end col-->
                </div>
                <!--end row-->
                <!-- </form> -->
                <!--end form-->
              </div>
            </div>
            <!--end col-->
            
            <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0  ">
              <div class=" sticky-bar">
                <div class="rounded shadow-lg p-4">
                  <div class="d-flex mb-1 justify-content-between">
                    <h5>{{formData.formData.name}}</h5>
                  </div>
                  <h6 class="mb-4">ul. {{formData.formData.addres}}</h6>
                  <div class="table-responsive ">
                    <table class="table table-center table-padding mb-0">
                      <tbody>
                        <tr>
                          <td class="h6 border-0">Rezerwacja</td>
                          <td class="text-end fw-bold border-0">{{price.toString() | slice: 0:-2 }}.{{ price.toString() |
                            slice: -2 }} zł</td>
                          </tr>
                          <div *ngIf="CzyJestRabat; then elseBlock2"></div>
                  <ng-template #elseBlock2>
                    
                    <tr>
                      <td class="h6">Rabat</td>
                      <td class="text-end fw-bold">{{DiscountView.toString() | slice: 0:-2 }}.{{ DiscountView.toString()
                        | slice: -2 }} zł</td>
                      </tr>
                    </ng-template>
                    
                    <div *ngIf="CzyJestRabat; then elseBlock else thenBlock"></div>
                    <ng-template #thenBlock>
                      <tr class="bg-light ">
                        <td class="h5  border-0 ">Łaczna cena:</td>
                        <td class="text-end  h4  border-0 " [class]="sss">{{OldPrice.toString() | slice: 0:-2 }}.{{
                          OldPrice.toString() | slice: -2 }} zł</td>
                        </tr>
                      </ng-template>
                      <ng-template #elseBlock>
                        
                        <tr class="bg-light">
                          <td class="h5 fw-bold border-0"></td>
                          <td class="text-end  h4 fw-bold border-0" [class]="sss" [style]="ssss">{{OldPrice.toString() |
                            slice: 0:-2 }}.{{ OldPrice.toString() | slice: -2 }} zł</td>
                          </tr>
                          <tr class="bg-light">
                            <td class="h5 fw-bold border-0">Łaczna cena:</td>
                            <td class="text-end  h4 fw-bold border-0" [class]="sss">{{NewPrice.toString() | slice: 0:-2 }}.{{
                              NewPrice.toString() | slice: -2 }} zł</td>
                            </tr>
                          </ng-template>
                          
                        </tbody>
                      </table>
                      
              <div class="col-10">
                <div class="row align-items-end">
                  <div class="col-8 ">
                    <div class="mb-3">
                      <label class="form-label">Kod rabatowy:</label>
                      <input name="name" id="lastname" type="text" class="form-control" placeholder=""
                      [(ngModel)]="OrderData.Discount">
                    </div>
                  </div>
                  <div class="col-4 ">
                    <div class="mb-3">
                      <button (click)="ChceckCupon($event)" class="btn btn-primary">Dodaj</button>
                    </div>
                  </div>
                </div>
              </div>
              
              <h5 class="mt-2">Płatność:</h5>
              <ul class="list-unstyled mt-2 mb-0">
                <ng-container *ngFor="let paymentMedia of formData.formData.paymentMethods; let i = index">
                  <li>
                    <div class="custom-control custom-radio custom-control-inline">
                      <div class="form-check mb-0 form-switch">
                        <input class="form-check-input" checked type="radio" name="flexRadioDefault"
                        [checked]="OrderData.PaymentMedia.Id === i"
                        (change)="onProfitSelectionChange(formData.formData.paymentMethods[i],i)" id="banktransfer">
                        <label class="form-check-label" for="banktransfer">{{paymentMedia.name}}</label>
                      </div>
                    </div>
                  </li>
                  <div *ngIf="paymentMedia.name=='Blik' && OrderData.PaymentMedia.Id === i">
                    <div class="col-12">
                      <div class="mb-3">
                        <label class="form-label"><span class="text-muted">(Podaj kod blik)<span
                          class="text-danger">*</span></span></label>
                          <input matInput #input maxlength="6" type="text" name="address2" id="address2"
                          [pattern]="blikPattern"
                          [ngClass]="{ 'invalid': BlikInput.invalid && BlikInput.touched }" #BlikInput="ngModel"
                          class="form-control" placeholder="" [(ngModel)]="OrderData.PaymentMedia.Args[0]"
                          (ngModelChange)="onBBlick(BlikInput.invalid)"
                          (change)="onProfitSelectionChange(formData.formData.paymentMethods[i],i)">
                        </div>
                        <p class="text-center" style="font-size: 11px;" (click)="showdecsc=!showdecsc">Administratorem Twoich danych osobowych jest mBank S.A. z siedzibą w Warszawie, 00-850 przy ul. Prostej 18, zwany dalej „Bankiem”.</p>
                        <div  style="font-size: 11px;" *ngIf="showdecsc">
                          <p>Funkcję Inspektora ochrony danych osobowych pełni pracownik Banku, z którym możesz kontaktować się pod adresem: Inspektordanychosobowych@mbank.pl</p>
                          <a>Powyższe dane osobowe podane przez Ciebie zostaną wykorzystane przez Bank w celu realizacji transakcji płatniczej. Podstawą prawną przetwarzania danych osobowych przez Bank jest umowa o świadczenie usługi „Integratora Płatności „PayNow”.</a>
                          <p class="mt-1">Twoje dane osobowe mogą być udostępniane następującym podmiotom: </p>
                          <ul> 
                            <li>instytucji upoważnionych do przetwarzania danych, w ramach nadzoru nad Bankiem np. KNF, UOKiK;</li> 
                            <li>podmiotom, którym powierzamy przetwarzanie danych przy wykonywaniu usług na naszą rzecz.</li>
                          </ul> 
                          Twoje dane osobowe będą przechowywane nie dłużej niż 5 lat po rozwiązaniu umowy przez którąś ze stron (na wypadek sporu sądowego). Po upływie powyższych okresów, Bank zanonimizuje Twoje dane osobowe.<br> Za pośrednictwem [BOK lub w placówkach Banku] masz prawo do dostępu i sprostowania swoich danych osobowych, ich przeniesienia oraz możesz żądać ich usunięcia, ograniczenia lub wniesienia sprzeciwu wobec ich przetwarzania.<br> Masz również prawo do wniesienia skargi do organu nadzorczego w zakresie ochrony Twoich danych osobowych w sposób wskazany na stronie 
                          <a class="data-processing-link" href="https://www.uodo.gov.pl" target="_blank">uodo.gov.pl</a><br> Więcej informacji na temat zasad przetwarzania danych, w tym Twoich uprawnień, można znaleźć na stronie 
                          <a class="data-processing-link" href="https://www.mbank.pl/rodo" target="_blank">mbank.pl/rodo</a>
                        </div>
                        
                      </div>
                      
                    </div>
                  </ng-container>
                </ul>
                
                <div *ngIf="CanYouBooK" class="mt-4 pt-1">
                  <div class="d-grid">
                    <button class="btn btn-primary" style="font-size: 22px;" [disabled]="buttonAllow"
                    (click)="send()" onclick="gtag_report_conversion(url)">Rezerwuje</button>
                  </div>
                </div>
                <div *ngIf="!CanYouBooK" class="mt-4 pt-1">
                  <div class="alert alert-danger text-center" style="font-size: 22px;" role="alert">
                    {{PlaceMessage}}
                  </div>
                </div>
                <div class="text-center mt-2" style="font-size: 12px;">
                  <p>To całkowity koszt, wliczyliśmy: {{diffInDays}} dni postoju oraz podatki.</p>
                </div>
                <div *ngIf="errorComuncate!=''" class="mt-4 pt-2">
                  <div class="alert alert-danger" role="alert">
                    <a class="text-center" style="color: white;">{{errorComuncate}}</a>
                  </div>
              </div>
            </div>
          </div>
          <h6 class="text-center pt-3">Twoje dane są poufne, dzięki szyfrowaniu SSL!</h6>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
</div>