import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AirportsAvailable } from '../../Model/AirportsAvailable';

@Injectable({
  providedIn: 'root'
})
export class GetAiportListService {


  constructor(
    private http: HttpClient
    ) { }

    
    conetingString :string = environment.api_url + "ParkingInstance/GetNameAllAirportsAvailable";

    GetListParksResults() : Observable<Array<AirportsAvailable>> {
      return this.http.get<Array<AirportsAvailable>>(this.conetingString) /* context: HttpContextToken('test') */
      
    }
}
