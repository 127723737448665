<div *ngIf="!hideFooter">
    <div *ngIf="footerVariant == 'bg-light';else execute_block" >
        <!-- Footer Start -->
        <footer class="footer bg-light">
            <div class="container" style="padding-top: 60px">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2" >
                        <a href="#" class="logo-footer">
                            <img src="assets/images/logo-dark.png" height="24" alt="">
                        </a>
                        <p class="mt-4 text-muted">Nasza strona to najlepsze rozwiązanie dla wszystkich, którzy szukają miejsca parkingowego. Dzięki naszej porównywarce parkingów, znajdziesz najlepsze oferty w Twojej okolicy. Dzięki nam zaoszczędzisz czas i pieniądze, a także zwiększysz swoją wygodę podróży. Sprawdź nas już dziś i przekonaj się, jak łatwo jest znaleźć idealne miejsce parkingowe.</p>
                        <ul class="list-unstyled social-icon social mb-0 mt-4">
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <!--end col-->

                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">Firma </h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a routerLink="/page-aboutus" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Więcej o nas</a></li>
                            <li><a routerLink="/page-services" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Usługi</a></li>
                            <li><a routerLink="/page-team" class="text-muted"><i class="uil uil-angle-right-b me-1"></i>
                                    Team</a></li>
                            <li><a routerLink="/page-pricing" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Pricing</a></li>
                            <li><a routerLink="/portfolio-modern-three" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i> Project</a></li>
                            <li><a routerLink="/page-jobs" class="text-muted"><i class="uil uil-angle-right-b me-1"></i>
                                    Careers</a></li>
                            <li><a routerLink="/page-blog-grid" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i> Blog</a></li>
                            <li><a routerLink="/auth-cover-login" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i> Login</a></li>
                        </ul>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">Usefull Links</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a routerLink="/page-terms" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Terms of Services</a></li>
                            <li><a routerLink="/page-privacy" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Privacy Policy</a></li>
                            <li><a routerLink="/documentation" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Documentation</a></li>
                            <li><a routerLink="/changelog" class="text-muted"><i class="uil uil-angle-right-b me-1"></i>
                                    Changelog</a></li>
                            <li><a routerLink="/components" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Components</a></li>
                        </ul>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">Newsletter</h5>
                        <p class="mt-4 text-muted">Sign up and receive the latest tips via email.</p>

                        <form>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="foot-subscribe foot-white mb-3">
                                        <label class="form-label">Write your email <span
                                                class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                            <input type="email" name="email" id="emailsubscribe"
                                                class="form-control bg-light border ps-5 rounded"
                                                placeholder="Your email : " required>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                                <div class="col-lg-12">
                                    <div class="d-grid">
                                        <input type="submit" id="submitsubscribe" name="send"
                                            class="btn btn-soft-primary" value="Subscribe">
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </form>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
        <!-- Footer End -->
    </div>
    <ng-template #execute_block>
        <footer class="footer">
            <div class="container" style="padding-top: 40px;">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a href="#" class="logo-footer">
                            <img src="assets/images/logo-light.png" height="24" alt="">
                        </a>
                        <p class="mt-4">Nasza strona to najlepsze rozwiązanie dla wszystkich, którzy szukają miejsca parkingowego. Dzięki naszej porównywarce parkingów, znajdziesz najlepsze oferty w Twojej okolicy. Dzięki nam zaoszczędzisz czas i pieniądze, a także zwiększysz swoją wygodę podróży. Sprawdź nas już dziś i przekonaj się, jak łatwo jest znaleźć idealne miejsce parkingowe.</p>
                        <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <!--end col-->

                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Firma</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a routerLink="/page-aboutus" [Disabled]="true" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Więcej o nas</a></li>
                            <li><a routerLink="/zaloguj" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i> Zaloguj sie</a></li>
                            <li><a routerLink="/kontakt" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                    Kontakt</a></li>
                            <li><a routerLink="/page-privacy" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Polityka prywatności</a></li>
                            <li><a routerLink="/regulamin" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Regulamin</a></li>
                        </ul>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Skontaktuj się z nami</h5>
                        <dl class="row mb-0">

                            <dt class="col-2 text-muted">
                                <i-feather name="phone" class="fea icon-sm"></i-feather>
                            </dt>
                            <dd class="col-10 text-muted">
                                <a href="tel:+48-505-489-595" class="text-muted">(+48) 505 48 95 95</a>
                            </dd>
                            <p class="mt-4">Infolinia czynna od poniedziałku do piątku 8:00-16:00</p>
                        </dl>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Newsletter</h5>
                        <p class="mt-4">Dołącz do naszego newslettera i bądź na bieżąco z najlepszymi promocjami i ofertami</p>
                        <form>
                            <div class="row">
                                <ng-container *ngIf="response">
                                    
                                    <div class="col-lg-12">
                                        <div class="foot-subscribe foot-white mb-3">
                                            <label class="form-label">Wpisz swój email <span
                                                class="text-danger">*</span></label>
                                                <div class="form-icon position-relative">
                                                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                    <input type="email" name="email" id="emailsubscribe" [(ngModel)]="newsletterEmail"
                                                    class="form-control ps-5 rounded" placeholder="Twój email:" required>
                                                </div>
                                            </div>
                                        </div>
                                        <!--end col-->
                                        <div class="col-lg-12">
                                            <div class="d-grid">
                                                <input type="submit" id="submitsubscribe" name="send"  (click)="subscribeNewsletter()"
                                                class="btn btn-soft-primary" value="Wyślij">
                                            </div>
                                        </div>
                                        <!--end col-->
                                    </ng-container>
                                    <ng-container *ngIf="!response && !badRequest">
                                        <div class="col-lg-12">
                                            <p class="mt-4">Dziekuje za zapisanie się do newslettera.</p>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="badRequest">
                                        <div class="col-lg-12">
                                            <ng-container *ngFor="let errors of communicateRequest">
                                                <p class="mt-4">
                                                    {{errors}}
                                                </p>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                            </div>
                            <!--end row-->
                        </form>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
    </ng-template>
</div>
<!--end footer-->
<footer class="footer footer-bar">
    <div class="container text-center">
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="text-sm-start">
                    <p class="mb-0">© {{year}} LeaveAndFly. Design with <i class="mdi mdi-heart text-danger"></i> by <a
                            href="javascript:void(0);" class="text-reset">SOFTWARE Kamil Cichoń</a>.
                    </p>
                </div>
            </div>
            <!--end col-->

            <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <ul class="list-unstyled text-sm-end mb-0">
                    <li class="list-inline-item"><a href="javascript:void(0)"><img
                        src="assets/images/payments/payU.png" class="avatar avatar-ex-sm"
                        title="Blik" alt=""></a></li>
                    <li class="list-inline-item ms-4"><a href="javascript:void(0)"><img
                                src="assets/images/payments/blik-logo.png" class="avatar avatar-ex-sm"
                                title="Blik" alt=""></a></li>
                    <li class="list-inline-item ms-4"><a href="javascript:void(0)"><img
                                src="assets/images/payments/paynow.png" class="avatar avatar-ex-sm" title="Discover"
                                alt=""></a></li>
                </ul>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</footer>
<!--end footer-->