import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbRatingConfig,NgbCarouselModule  } from '@ng-bootstrap/ng-bootstrap';


import { dataModel } from '../../Model/DataModel/dataModelComponent';
import { GlobalDateDataService } from '../../services/globalDateData/global-date-data.service';
import { ParkListApiService } from '../../services/park-list-api/park-list-api.service';
import { ParkingSearchOptions } from '../../Model/ParkList/ParkingSearchOptions';
import { parkingInstanceList } from '../../Model/ParkList/parkingInstanceList';
import { DatePipe } from '@angular/common';
import { DomSanitizer, Meta, SafeResourceUrl, Title } from '@angular/platform-browser';
import { AdvantagesOfParkingModel } from '../../Model/BookingList/advantagesOfParkingModel';

@Component({
  selector: 'app-list-search',
  templateUrl: './list-search.component.html',
  styleUrls: ['./list-search.component.css']
})
export class ListSearchComponent implements OnInit {

  constructor(private GlobalData : GlobalDateDataService,
              private Get : ParkListApiService,
              private route: Router,
              private config: NgbRatingConfig,
              private datePipe: DatePipe,
              public sanitizer: DomSanitizer,              
              private meta: Meta,
              private titleService:Title) 
              {
                this.titleService.setTitle("Rezerwuj parking Pyrzowice/Katowice");
                config.max = 5;
                config.readonly = true;  
                this.meta.updateTag({ name: 'description', content: "Zarezerwuj parking lotniczy w Pyrzowicach online przed wylotem! Wygodne i bezpieczne miejsce na Twój samochód. Rezerwuj teraz i ciesz się spokojną podróżą."});
                this.meta.updateTag({ name: 'keywords', content: "parking lotnisko katowice, parking lotnisko pyrzowice, parking w pyrzowicach, lotnisko parking, parking przy lotnisku, parkingi lotniskowe, tanie parkingi, parking lotnisko, Byczy Parking, MTM parking"});
              }       

  ngOnInit(): void {
    this.GlobalData.GetDataObj().subscribe(date =>{
      this.dataModel.checkIn = date.checkIn
      this.dataModel.hourIn = date.hourIn
      this.dataModel.checkOut = date.checkOut
      this.dataModel.hourOut = date.hourOut
  
      this.ChecInUrl =this.datePipe.transform(date.checkIn, 'dd-MM-yyyy');
      this.ChecOutUrl = this.datePipe.transform(date.checkOut, 'dd-MM-yyyy');

      this.Search()
    })
  }

  ChecInUrl:string='';
  ChecOutUrl:string='';

  now = new Date();
  Today: Date = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate(), 0, 0, 0)

  dataModel :dataModel ={
    checkIn: new Date(),    
    hourIn :"12:00",
    checkOut: new Date(new Date().getTime() + 24 * 60 * 60 * 1000*7),
    hourOut:"15:00"
  }
  
  searchOptions : ParkingSearchOptions ={
    checkIn: "",
    checkOut: "",
    destinationAirport:  this.checkUrl(),
    pageSize: 20,
    page: 1
  }

  checkUrl(){
    let string = this.route.routerState.snapshot.url.split("?");  
    let url =string[0].substring(1).replace('-',' ').replace('-',' ')
    return url
  }

  timeCheckIn = 20;
  timeCheckOut = new Date();

  parkingInstanceList: parkingInstanceList[]

  Search(){
    this.searchOptions.destinationAirport= this.checkUrl()
    this.CountDay()
    this.searchOptions.checkIn = this.getCorectData(this.dataModel.hourIn,this.dataModel.checkIn)
    this.searchOptions.checkOut = this.getCorectData(this.dataModel.hourOut,this.dataModel.checkOut)
    this.ChecInUrl =this.datePipe.transform(this.dataModel.checkIn, 'yyyy-MM-dd') +'H'+ this.dataModel.hourIn;
    this.ChecOutUrl = this.datePipe.transform( this.dataModel.checkOut, 'yyyy-MM-dd')+'H'+ this.dataModel.hourOut;

    this.Get.GetListParksResults(this.searchOptions).subscribe(date =>{
     this.parkingInstanceList= date.parkingInstanceList
    })
  }
  
  getCorectData(time:string, data:Date):string{
    let dateStr = data.getFullYear()+ "-"+this.pad(data.getMonth()+1)+ "-" + this.pad(data.getDate())
    let dateTimeStr = `${dateStr}T${time}:00.000Z`;
    return dateTimeStr;
  }
  private pad(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }

  click(data:string, index:number){
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.parkingInstanceList[index].urlMap);
    if(this.show==data){
      this.show=""
    }
    else{
      this.show=data;
    }
  }

  show:string='';
  urlSafe: SafeResourceUrl;

  setActualWindow(name){
    this.actualWindow=name
  }
  

  actualWindow: string ='Opis'


  atuts :AdvantagesOfParkingModel[] =[
    {
      name: 'Ogrodzony ',
      icon:"h4 me-2 mdi mdi-shield-car "
    },
    {
      name: 'Oświetlony ',
      icon:"h4 me-2 mdi mdi-lightbulb-on"
    },
    {
      name: 'Monitorowany',
      icon:"h4 me-2 mdi mdi-cctv"
    },
    {
      name: 'Obsługa 24/7',
      icon:"h4 me-2 mdi mdi-account-multiple-check"
    },
  ]
  atuts2 :AdvantagesOfParkingModel[] =[
    {
      name: 'Wystawia fakturę',
      icon:""
    },
    {
      name: 'Bezpłatna anulacja',
      icon:""
    },
    {
      name: 'Darmowy transfer',
      icon:""
    }
  ]
  options: string[] =['Opis','Zdjęcia','Opinie', 'Mapa']
  
  CountDay(){
    let year1 = this.dataModel.checkOut.getFullYear();
    let month1 = ('0' + (this.dataModel.checkOut.getMonth() + 1)).slice(-2);
    let day1 = ('0' + this.dataModel.checkOut.getDate()).slice(-2);
    let checkOut= new Date(`${year1}-${month1}-${day1}`);

    let year = this.dataModel.checkIn.getFullYear();
    let month = ('0' + (this.dataModel.checkIn.getMonth() + 1)).slice(-2);
    let day = ('0' + this.dataModel.checkIn.getDate()).slice(-2);
    let checkIn=new Date(`${year}-${month}-${day}`);

    let time =(checkOut.getTime()-checkIn.getTime())/24/60/60/1000

    this.diffInDays = time+1;
  }
  diffInDays:number=1;

}
