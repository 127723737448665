<!-- Start Contact -->
<section class="section pt-5 mt-4" id="home">
  <!-- <div class="container-fluid">
    <div class="row">
      <div class="col-12 p-0">
        <div class="card map border-0">
          <div class="card-body p-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
              style="border:0" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      end col
    </div>
    end row
  </div> -->
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <div class="card custom-form rounded border-0 shadow p-4" *ngIf="!successfullySent">
          <form method="post" name="myForm" (ngSubmit)="submitForm()">
            <p id="error-msg" class="mb-0"></p>
            <div id="simple-msg"></div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Twoje imię <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                    <input name="name" id="name" type="text" class="form-control ps-5" [(ngModel)]="contact.name" placeholder="Imię :">
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Twoj email <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                    <input name="email" id="email" type="email" class="form-control ps-5" [(ngModel)]="contact.email" placeholder="Email :">
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Temat</label>
                  <div class="form-icon position-relative">
                    <i-feather name="book" class="fea icon-sm icons"></i-feather>
                    <input name="subject" id="subject" class="form-control ps-5"  [(ngModel)]="contact.topic" placeholder="Temat :">
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Opis <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                    <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                      placeholder="Widomość :"  [(ngModel)]="contact.description"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="d-grid">
                  <button type="submit" id="submit" name="send" class="btn btn-primary">Wyślij wiadomość</button>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </form>
        </div>
        <div class="card custom-form rounded border-0 shadow p-4" *ngIf="successfullySent">
          <h3 class="text-center" style="margin: 60px 0px 60px 0px;">Dziękujemy za wiadomość. Postaramy się odpowiedzieć tak szybko, jak to możliwe</h3>
        </div>
        <!--end custom-form-->
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 order-1 order-md-2">
        <div class="title-heading ms-lg-4">
          <h4 class="mb-4">Główne źródła kontaktowe</h4>
          <p class="text-muted">Jeżeli nie znalazłeś odpowiedzi na twoje pytania w naszym  <span class="text-primary fw-bold">Q&A</span> Skorzystaj z formularza, postaramy się odpowiedzieć tak szybko jak to możliwe</p>
          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="mail" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0">Email</h6>
              <a href="mailto:contact@example.com" class="text-primary">kontakt@app.leaveandfly.com</a>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="phone" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0">Obługa klienta</h6>
              <a href="tel:+48-505-489-595" class="text-primary">+48 505 48 95 95</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->