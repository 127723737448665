import { Component, OnInit } from '@angular/core';

interface feature {
  icon: string;
  title: string;
};

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  /**
   * Clients Logo
   */
   featuresdata: feature[] = [
    {
      icon: "monitor",
      title: "Responsywność"
    },
    {
      icon: "heart",
      title: "Kompatybilność"
    },
    {
      icon: "settings",
      title: "Łatwe do dostosowania"
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
