import { AirportsAvailable } from '../../Model/AirportsAvailable';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { GetAiportListService } from '../../services/mainpage/get-aiport-list-service';
import { GlobalDateDataService } from '../../services/globalDateData/global-date-data.service';
import { flatpickrFactory } from 'src/app/app.module';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})

/***
 * Travel Component
 */
export class IndexComponent implements OnInit {

  /***
   * Nav bg light class add
   */
  navClass = 'nav-light';

  constructor(private services: GetAiportListService,
              private GlobalData : GlobalDateDataService) { }

  aiportList:AirportsAvailable[]=[];

  open(name, link){
    this.GlobalData.UpdateSelectedAirport(name)
    this.Value=name
    this.Route=link
  }
  Value:string;

  SeletRout(value){
    this.Route=value
  }
  Route:string;
  
  modelData:ModelTwoDate;
  checkout = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

  sss(){
    this.GlobalData.UpdateCheckInView(this.modelData.from)
    this.GlobalData.UpdateCheckOutView(this.modelData.to)
    this.GlobalData.UpdateSelectedAirport(this.Value)
  }
  minDate = new Date(new Date().getTime() -1 * 60 * 60 * 1000)
  /***
   * Destination Slider
   */
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    autoplay: true,
    navSpeed: 700,
    dots: true,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 5
      },
      900: {
        items: 8
      }
    },
    nav: false
  };

  /**
   * Client Testimonial Data
   */
  testimonialData = [
    {
      profile: "assets/images/client/01.jpg",
      name: "Kinga",
      message: `"Polecam! Blisko lotniska z przewozem na i z lotniska w cenie. Obsługa parkingu profesjonalnie obsługuje klienta nie ma problemów w kontakcie z pracownikami. Cena bardzo konkurencyjna dla pozostałych parkingów w okolicy :)  jeśli ktoś potrzebuje zostawić swój samochód w bezpiecznym miejscu warto skorzystać :) "`
    },
    {
      profile: "assets/images/client/02.jpg",
      name: "Barbara ",
      designation: "M.D",
      message: `"Miła obsługa, bezpieczny parking, panowie zawsze odbierają telefony, bezproblemowy transfer w obie strony z praktycznie znikomym czasem oczekiwania. Polecam "`
    },
    {
      profile: "assets/images/client/03.jpg",
      name: "Robert",
      designation: "P.A",
      message: `" Korzystam od lat i jeszcze się nie zawiodłem. Profesjonalnie i sprawnie przeprowadzane "parkowanie" i transport z i na lotnisko do którego rzeczywiście jest parę minut. Specyficzny humor i podejście pracowników - dla mnie ok ale niektórych może razić  ;) "`
    },
    {
      profile: "assets/images/client/04.jpg",
      name: "Patryk",
      designation: "Manager",
      message: `" Najlepszy parking w Pyrzowicach, szybko, sprawnie, dobre ceny, blisko. Obsługa bardzo dobra. Naprawdę uczciwa i solidna firma, polecam!! "`
    },
    {
      profile: "assets/images/client/05.jpg",
      name: "Justyna",
      designation: "Developer",
      message: `" Obsługa bezkontaktowa, wszystko odbywa się elektronicznie. Kierowca autokaru bardzo grzeczny. Spóźniliśmy się na odjazd spod lotniska. Zobaczyliśmy tylko tył pojazdu. Jeden telefon, kierowca zawrócił i nas zabrał. Super. Polecam."`
    },
    {
      profile: "assets/images/client/06.jpg",
      name: "Mikołaj",
      designation: "Designer",
      message: `" Obsługa, transport, cena, proces rezerwacji- wszystko ok jedyny minus to dojazd do parkingu i nawierzchnia na nim… "`
    }
  ];

  cos:string;
  ngOnInit(): void {
    this.services.GetListParksResults().subscribe( items => this.aiportList = items)
    this.GlobalData.message$
    .subscribe(message => {
      this.cos = message;
    });
  }

}
export interface ModelTwoDate{

  to:Date,
  from:Date
}

