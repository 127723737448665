<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <h4 class="title"> Regulamin serwisu internetowego www.leaveandfly.com</h4>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/index">Strona główna</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0)">Docs</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Regulamin leaveandfly</li>
                </ul>
              </nav>
              <ul class="list-unstyled mt-4">
                <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Ostatnia Aktualizacja :</span> 26 Czerwca 2023</li>
              </ul>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->
  
  <!-- Shape Start -->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--Shape End-->
  
  <!-- Documentation Start -->
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3>Regulamin serwisu internetowego www.leaveandfly.com</h3>
            <div class="mt-4 p-4 bg-light rounded shadow">
                <p>§1 Podstawowe definicje</p>
                <p>§2 Postanowienia ogólne</p>
                <p>§3 Warunki świadczenia usług</p>
                <p>§4 Umowa świadczenia usług</p>
                <p>§5 Postępowanie reklamacyjne</p>
                <p>§6 Odstąpienie pouczenie obowiązkowe</p>
                <p>§7 Rękojmia</p>
                <p>§8 Odpowiedzialność</p>
                <p>§9 Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń</p>
                <p>§10 Postanowienia dotyczące przedsiębiorców</p>
                <p>§11 Metody Płatności</p>
                <p>§12 Postanowienia końcowe</p>
              
              <h5>Wstęp</h5>
              <a>
                Regulamin Serwisu. Szanowny Użytkowniku niniejszy Regulamin reguluje sposób korzystania z wyżej wymienionego Serwisu internetowego, prawa i obowiązki Użytkownika i Serwisu wynikające z obowiązującego prawa. Regulamin składa się z czterech głównych części: 
              </a>
              <ol>
                <li>w § od 1 do 3 - znajdują się ogólne uregulowania niniejszego Regulaminu;</li>
                <li>w § 4 - został opisany proces rejestracji i świadczenia usług;</li>
                <li>w § od 5 do 7 - zawarto regulacje związane ze stwierdzeniem wadliwości usług jak i prawo odstąpienia od świadczenia i korzystania z usług;</li>
                <li>w § od 8 do 12 - zawarto wszystkie pozostałe uregulowania w tym końcowe.</li>
              </ol>

                <h5>§1 Podstawowe definicje</h5>
                <ol>
                    <li>Serwis internetowy/Usługodawca – serwis internetowy dostępny pod adresem niniejszej strony będący jednocześnie Usługodawcą. Właścicielem Serwisu Internetowego jest Kamil Cichoń, prowadzący działalność gospodarczą pod firmą SOFTWARE Kamil Cichoń z siedzibą ul. Dworcowa 8a, 42-625 Ożarowice. wpisany do Centralnej Ewidencji i Informacji o Działalności Gospodarczej, prowadzonej przez ministra właściwego ds. rozwoju, REGON: 522138250 NIP: 6452575239</li>
                    <li>Adres Właściciela – ilekroć w Regulaminie jest mowa o adresie Właściciela rozumie się przez to następujące dane:
                      <ul>
                        <li>siedzibę: Dworcowa 8a, 42-625 Ożarowice</li>
                        <li>adres mailowy:  kontakt@app.leaveandfly.com</li>
                        <li>Infolinia - 505 48 95 95 (czynna Pon-Pt 8.00-16.00)</li>
                      </ul>
                    </li>
                    <li>Użytkownik/Usługobiorca – osoba fizyczna która ukończyła 18 lat i posiadająca pełną zdolność do czynności prawnych, a w wypadkach przewidzianych przez przepisy powszechnie obowiązujące także osoba fizyczna posiadająca ograniczoną zdolność do czynności prawnych, osoba prawna albo jednostka organizacyjna nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną, która zawarła lub zamierza zawrzeć umowę z Serwisem internetowym.</li>
                    <li>Konsument – art. 221 KC: osoba fizyczna dokonująca z Serwisem internetowym czynności prawnej niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową.</li>
                    <li>Umowa zawarta na odległość – oznacza umowę zawartą pomiędzy Serwisem internetowym a Użytkownikiem w ramach zorganizowanej procedury systemu zawierania umów na odległość, bez konieczności uczestnictwa obu stron umowy w jednym miejscu i czasie, zawartej za pomocą jednego lub większej liczby środków niezbędnych do porozumiewania się na odległość do chwili zawarcia umowy włącznie.</li>
                    <li>Usługa – usługa świadczona drogą elektroniczną oraz stacjonarną przez Usługodawcę na rzecz Usługobiorcy (Klienta) za pośrednictwem niniejszego Serwisu internetowego tj. umożliwienie Klientom za pośrednictwem Internetu (drogą elektroniczną) korzystanie z oferty usług parkingowych, dokonywanie rezerwacji oraz nabywanie usługi parkingowe oferowane przez Parkingi</li>
                    <li>Parkingi - parkingi, z którymi Usługodawca współpracuje na podstawie odrębnych umów w zakresie oferowania Usług parkingowych oraz ich rezerwacji za pomocą Serwisu.</li>
                    <li>Obniżki cen -  ewentualne rabaty, PROMOCJE i obniżki cen można sprawdzić na wykresie przy wyświetlanej cenie produktu. Historia ceny możliwa będzie do obejrzenia przez 30 dni wstecz. Zapis cen następuje w sposób weryfikowalny i automatyczny.</li>
                    <li>Ceny - ceny produktów w serwisie internetowym są stałe w danym czasie i nie wpływają na nie żadne algorytmy ustalania cen niezależnie jak klient trafia na stronę, jakich przeglądarek używa. Nie ma też znaczenia płeć, wiek itp</li>
                    <li>Ustawa o prawach Konsumenta, ustawa  – ustawa z dnia 30 maja 2014 r. o prawach Konsumenta (dziennik ustaw z roku 2014 pozycja 827 z późniejszymi zmianami).</li>
                    <li>RODO- Rozporządzenie Rady UE 2016/679 (z dnia 27 kwietnia 2016 r. Dz. Urz. UE.L. Nr 119).</li>
                  </ol> 
                <h5>§2 Postanowienia ogólne</h5>
                <ol>
                    <li>Serwis internetowy niniejszym oświadcza, że zobowiązuje się do świadczenia usług na rzecz Usługobiorcy w sposób rzetelny i odpowiadający obowiązującym przepisom prawa,  zasadom  współżycia społecznego oraz w sposób uregulowany w tym Regulaminie.</li>
                    <li>Usługodawca oświadcza, że w związku z koniecznością informowania o pochodzeniu opinii, opinie zamieszczone na naszej stronie pochodzą od rzeczywistych klientów którzy zakupili i używali nasze produkty. Opinie zostały wystawione zgodnie z przesłaną prośba o ich wystawienie po potwierdzeniu że Klient skorzystał z usługi. Przewidujemy możliwość importowania opinii zamieszczonych w zakładce Google w dziale wizytówki naszej firmy. Obiecujemy dopełnić wszelkich formalności i starań by opinie były rzeczywiste i odpowiadały prawdzie a te wystawione nienależycie były usuwane.</li>
                    <li>Obowiązkowe podstawy prawne w/w uregulowań:
                      <ol>
                        <li>Dyrektywa Parlamentu Europejskiego i Rady (UE) 2019/2161 z dnia 27 listopada 2019 r. zmieniająca dyrektywę Rady 93/13/EWG i dyrektywy Parlamentu Europejskiego i Rady 98/6/WE, 2005/29/WE oraz 2011/83/UE w odniesieniu do lepszego egzekwowania i unowocześnienia unijnych przepisów dotyczących ochrony konsumenta, OJ L 328, 18.12.2019, s. 7–28.</li>
                        <li>Fałszowanie opinii w internecie – doświadczenia konsumentów, na podstawie badania PBS Sp. z o.o. na zlecenie UOKIK, publikacja dostępna https://uokik.gov.pl/aktualnosci.php?news_id=17411</li>
                        <li>Dyrektywa 2005/29/WE Parlamentu Europejskiego i Rady z dnia 11 maja 2005 r. dotycząca nieuczciwych praktyk handlowych stosowanych przez przedsiębiorstwa wobec konsumentów na rynku wewnętrznym oraz zmieniająca dyrektywę Rady 84/450/EWG, dyrektywy 97/7/WE, 98/27/WE i 2002/65/WE Parlamentu Europejskiego i Rady oraz rozporządzenie (WE) nr 2006/2004 Parlamentu Europejskiego i Rady („Dyrektywa o nieuczciwych praktykach handlowych”) (OJ L 149, 11.6.2005, p. 22–39, z późn. zmianami).</li>
                        <li>Zawiadomienie Komisji – Wytyczne dotyczące wykładni i stosowania dyrektywy 2005/29/WE Parlamentu Europejskiego i Rady dotyczącej nieuczciwych praktyk handlowych stosowanych przez przedsiębiorstwa wobec konsumentów na rynku wewnętrznym, OJ C 526, 29.12.2021, s. 1–129.</li>
                        <li>Dyrektywa Parlamentu Europejskiego i Rady 2011/83/UE z dnia 25 października 2011 r. w sprawie praw konsumentów, zmieniająca dyrektywę Rady 93/13/EWG i dyrektywę 1999/44/WE Parlamentu Europejskiego i Rady oraz uchylająca dyrektywę Rady 85/577/EWG i dyrektywę 97/7/WE Parlamentu Europejskiego i Rady (OJ L 304, 22.11.2011, p. 64–88, z późn. zmianami).</li>
                      </ol>
                    </li>
                    <li>Serwis internetowy oświadcza, że przestrzega wszelkich wymaganych zasad ochrony danych osobowych Usługobiorców jakie są  przewidziane między innymi Ustawą z dnia 10 maja 2018 r. o ochronie danych osobowych oraz zgodnie z przepisami RODO. Usługobiorca wyraża zgodę na gromadzenie, przechowywanie i przetwarzanie przez Serwis internetowy danych osobowych wyłącznie w celu bezpośrednio związanym z realizacją zadań serwisu. Szczegółowe warunki gromadzenia, przetwarzania oraz ochrony danych osobowych przez Serwis internetowy określone zostały w „Polityce prywatności” Serwisu internetowego.</li>
                    <li>Usługobiorca wyraża zgodę na gromadzenie i przetwarzanie przez Serwis danych osobowych w rozumieniu Ustawy z dnia 10 maja 2018 r. o ochronie danych osobowych oraz zgodnie z przepisami RODO. Dane mogą być przekazywane innemu podmiotowi tylko w sytuacji prawem wymaganych bądź niezbędnych dochodzenia roszczeń.</li>
                    <li>Użytkownik ma możliwość zapoznania się z Regulaminem, akceptując jego treść poprzez oznaczenie odpowiedniego pola w formularzu. Do korzystania z Serwisu internetowego konieczna jest akceptacja postanowień regulaminu.</li>
                    <li>Administratorem danych osobowych jest Serwis internetowy, a dane te podlegają ochronie zgodnie z Ustawą z dnia 10 maja 2018 r. o ochronie danych osobowych oraz zgodnie z przepisami RODO.</li>
                    <li>Administrator danych stosuje odpowiednie środki techniczne i organizacyjne zapewniające ochronę danych osobowych miarodajnie do zagrożeń oraz kategorii danych objętych ochroną. Przede wszystkim chroni dane przed ich udostępnieniem, zabraniem, przetwarzaniem, utratą, zmianą uszkodzeniem lub zniszczeniem przez osoby do tego nieuprawnione.</li>
                    <li>Każdej osobie, której dane są przetwarzane przysługuje prawo do:</li>
                    <ol>
                      <li>nadzorowania i kontroli przetwarzania danych osobowych, dla których Serwis internetowy prowadzi zbiór danych Użytkowników w/w Serwisu internetowego;</li>
                      <li>uzyskania wyczerpującej informacji, czy taki zbiór istnieje i jest prowadzony przez Serwis internetowy;</li>
                      <li>ustalenia kto jest administratorem danych, ustalenia jego adresu, siedziby, nazwy, w sytuacji gdy administratorem jest osoba fizyczna do ustalenia jej imienia i nazwiska oraz miejsca zamieszkania;</li>
                      <li>uzyskania informacji o celu, zakresie, sposobie, czasie przetwarzania danych zawartych w takim zbiorze;</li>
                      <li>uzyskania informacji w powszechnie zrozumiałej formie treści tych danych;</li>
                      <li>poznania źródła, z którego pochodzą dane, które jej dotyczą, chyba że administrator danych jest zobowiązany do zachowania w tym zakresie w tajemnicy informacji niejawnych lub zachowania tajemnicy zawodowej;</li>
                      <li>żądania uzupełnienia, uaktualnienia, sprostowania danych osobowych, czasowego zawieszenia lub ich usunięcia, jeżeli są one niekompletne, nieaktualne, nieprawdziwe lub zostały zebrane z naruszeniem ustawy albo są już zbędne do realizacji celu, dla którego zostały zebrane.</li>
                    </ol>
                    <li>Użytkownikowi zgodnie z punktem 9 przysługuje prawo wglądu w treść przetwarzanych danych osobowych, ich poprawiania, a także żądania usunięcia tych danych. Administrator danych osobowych jest obowiązany, do uzupełnienia, uaktualnienia, sprostowania danych, czasowego lub stałego wstrzymania przetwarzania lub ich usunięcia ze zbioru na bieżąco i od razu po zgłoszeniu, chyba że żądanie dotyczy danych osobowych, co do których tryb ich uzupełnienia, uaktualnienia lub sprostowania określają odrębne przepisy prawa w tym ustawy.</li>
                    <li>Usługobiorca zobowiązuje się do korzystania z Serwisu internetowego zgodnie z obowiązującymi przepisami prawa i zasadami współżycia społecznego.</li>
                    <li>Usługobiorca korzystający z Usług Serwisu internetowego, zobowiązany jest do przestrzegania niniejszego Regulaminu w zakresie jaki jest niezbędny do realizacji zadań Serwisu i w zakresie który nie jest sprzeczny z obowiązującym prawem oraz z zasadami współżycia społecznego.</li>
                    <li>Usługodawca jest uprawniony do udostępnienia uprawnionym organom państwowym treści, materiałów oraz danych, w tym adresów IP Usługobiorców, którzy korzystali z Serwisu w określony sposób, w szczególności, gdy jest to niezbędne w celu zapobiegania lub ścigania przestępstw. W takiej sytuacji Usługodawca nie opowiada także za ewentualne zablokowanie dostępu do określonych danych i informacji.</li>
                    <li>Art. 556(4) Zawarte w niniejszym dziale przepisy dotyczące konsumenta, z wyjątkiem art. 558 § 1 zdanie drugie, stosuje się do osoby fizycznej zawierającej umowę bezpośrednio związaną z jej działalnością gospodarczą, gdy z treści tej umowy wynika, że nie posiada ona dla tej osoby charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nią działalności gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej.</li>
                    <li>Weryfikacja tego, czy dana czynność ma zawodowy charakter, będzie odbywać się w oparciu o CEiDG — Centralną Ewidencję i Informację o Działalności Gospodarczej — a konkretnie o wpisane tam kody PKD określające rodzaje działalności gospodarczej.</li>
                    <li>Przedsiębiorcy prowadzący jednoosobowo działalność uzyskają uprawnienia w zakresie:</li>
                    <ul>
                      <li>niedozwolonych klauzul stosowanych we wzorcach umownych;</li>
                      <li>rękojmi za wady rzeczy sprzedanej;</li>
                      <li>roszczenia regresowego do poprzedniego sprzedawcy w związku z wykonaniem reklamacji konsumenta;</li>
                      <li>prawa odstąpienia od umowy zawartej na odległość lub poza lokalem przedsiębiorstwa w terminie 14 dni;</li>
                    </ul>
                    <li>Przedsiębiorcy prowadzący jednoosobowo działalność gospodarczą nadal nie będą mogli korzystać z pomocy instytucji wspierających konsumentów w ochronie ich praw, w tym z pomocy Powiatowych/Miejskich Rzeczników Konsumenta czy UOKIK.</li>
                 </ol>
                <h5>§3 Warunki świadczenia usług</h5>
                <ol>
                  <li>Niniejszy Serwis internetowy świadczy usługi za pomocą drogi elektronicznej.</li>
                  <li>Usługa określona w pkt. 1 wymaga dostępu do sieci internetowej.</li>
                  <li>Korzystać z Serwisu internetowego można 24 godz. na dobę 7 dni w tygodniu. Serwis internetowy zastrzega sobie prawo do robienia przerw technicznych celem poprawy funkcjonalności Serwisu oraz naprawy błędów.</li>
                  <li>W celu korzystania z Usługi Pośredniczenia Klient zobowiązany jest do dokonania rejestracji w Serwisie. Rejestracja jest bezpłatna</li>
                  <li>Klient zobowiązany jest do podania prawdziwych, aktualnych i komplet- nych danych podczas rezerwacji oraz wypełniania formularza rezerwacji</li>
                  <li>Klient ponosi odpowiedzialność za zachowanie poufności hasła do Konta i za wszelkie czynności dokonywane w ramach Konta</li>
                  <li>Klient zobowiązany jest do korzystania z Serwisu zgodnie z obowiązującymi przepisami prawa oraz zasadami współżycia społecznego. Wszelkie naruszenia przepisów Regulaminu przez Klienta mogą skutkować ograniczeniem lub zablokowaniem dostępu do Serwisu.</li>
                  <li>Serwis może być czasowo niedostępny z powodu konserwacji, naprawy lub innych przyczyn technicznych. Usługodawca zastrzega sobie prawo do wprowadzenia czasowych przerw w funkcjonowaniu Serwisu w celu utrzymania jego sprawności i bezpieczeństwa.</li>
                  <li>Usługodawca zastrzega sobie prawo do wprowadzania zmian w Serwisie oraz w Regulaminie. Klient zostanie powiadomiony o wprowadzonych zmianach za pomocą Powiadomienia przesłanego na podany przez Klienta adres mailowy.</li>
                  <li>W przypadku stwierdzenia naruszenia Regulaminu lub innych uzasadnionych przyczyn, Usługodawca ma prawo odmówić udzielenia Usługi Pośredniczenia lub zablokować dostęp do Serwisu Klientowi, z zastrzeżeniem usunięcia konta w ciągu 14 dni od daty otrzymania stosownego Powiadomienia przez Klienta.</li>
                  <li>Wszelkie informacje, materiały i dane udostępniane w Serwisie stanowią własność intelektualną Usługodawcy i są chronione prawami autorskimi. Kopiowanie, rozpowszechnianie, przetwarzanie lub wykorzystywanie w jakikolwiek sposób tych materiałów i danych bez zgody Usługodawcy jest zabronione.</li>
                  <li>Usługodawca nie ponosi odpowiedzialności za jakiekolwiek szkody powstałe w związku z korzystaniem lub niemożnością korzystania z Serwisu, w tym za szkody wynikłe z błędów, przerw w dostępie, usunięcia lub uszkodzenia danych, wirusów lub innych technologicznie szkodliwych materiałów.</li>
                  <li>Usługodawca nie ponosi odpowiedzialności za działania i ofertę Parkingów oraz za jakość, terminowość i zgodność z umową świadczonych przez Parkingi usług parkingowych. Usługodawca działa jedynie jako pośrednik w procesie rezerwacji i zakupu usług parkingowych świadczonych przez Parkingi. Odpowiedzialność za świadczenie usług parkingowych spoczywa na Parkingach</li>
                  <li>Do założenia konta wymagane są takie dane jak: adres e-mail oraz numer telefonu komórkowego użytkownika. Za pomocą adresu e-mail lub numeru telefonu komórkowego nastąpi aktywacja konta poprzez link zwrotny przesłany na ten adres lub kod wysłany na numer telefonu.</li>
                  <li>Podczas korzystania z Serwisu internetowego Klient ma możliwość dokonać subskrypcji Newslettera, która jest jednoznaczna z wyrażeniem zgody na otrzymywanie bezpłatnego Newslettera.</li>
                  <li>Subskrypcja Newslettera polega na zaznaczeniu określonej opcji w procesie rejestracji Klienta w Serwisie internetowym lub dołączenie poprzez wysłanie adresu email w stopce strony.</li>
                  <li>Klient ma prawo w każdym momencie zrezygnować z usługi Newslettera. Subskrypcję można anulować poprzez wysłanie wiadomości e-mail na adres mailowy Usługodawcy.</li>
                  <li>Klient subskrybując Newsletter wyraża zgodę na wysyłanie przez Usługodawcę Serwisu internetowego za jego pośrednictwem reklam oraz informacji handlowych, na konto mailowe Klienta podane podczas rejestracji.</li>
                </ol>
                <h5>§4 Umowa świadczenia usług</h5>
                <ol>
                  <li>Usługa Pośredniczenia polega na udostępnieniu Klientowi platformy internetowej umożliwiającej rezerwację i zakup usług parkingowych oferowanych przez Parkingi.</li>
                  <li>Klient ma możliwość dokonania rezerwacji usługi parkingowej poprzez wypełnienie formularza rezerwacyjnego dostępnego w Serwisie.</li>
                  <li>Usługodawca nie ponosi odpowiedzialności za dostępność usług parkingowych oferowanych przez Parkingi. Informacje o dostępności, cenach i innych warunkach usług parkingowych są podawane przez Parkingi, a Usługodawca działa jedynie jako pośrednik w procesie rezerwacji i zakupu tych usług.</li>
                  <li>Klient dokonuje płatności za usługę parkingową bezpośrednio do Parkingu zgodnie z podanymi w Serwisie warunkami płatności.</li>
                  <li>Usługodawca nie ponosi odpowiedzialności za jakość, terminowość i zgodność z umową świadczonych przez Parkingi usług parkingowych. Odpowiedzialność za świadczenie usług parkingowych spoczywa na Parkingach</li>
                  <li>Usługodawca nie ponosi odpowiedzialności za ewentualne zmiany lub odwołania rezerwacji dokonane przez Parkingi. W przypadku zmiany lub odwołania rezerwacji Klient powinien skontaktować się bezpośrednio z Parkingiem.</li>
                  <li>Usługodawca zastrzega sobie prawo do zmiany warunków świadczenia Usługi Pośredniczenia w każdym czasie. Klient zostanie poinformowany o zmianach za pośrednictwem wiadomości wysłanej na podany podczas rejestracji adres mailowy.</li>
                </ol> 
                <h5>§5 Postępowanie reklamacyjne</h5>
                <ol>
                  <li>Usługobiorca ma prawo do składania reklamacji w zakresie Usług świadczonych przez Usługodawcę w ramach Serwisu internetowego.</li>
                  <li>Podmiotem uprawnionym do rozpatrywania reklamacji jest Usługodawca.</li>
                  <li>eklamacje powinny być przesyłane na adres znajdujący się paragrafie 1 pkt 3 w formie pisemnej lub elektronicznej (wiadomość mailowa w polu temat wiadomości powinna zawierać słowo: "reklamacja") i powinny zawierać:</li>
                  <ul>
                    <li>przedmiot reklamacji oraz uzasadnienie reklamacji, wskazanie i opisanie niezbędnych okoliczności,</li>
                    <li>oznaczenie Usługobiorcy (imię, nazwisko, adres, adres e-mail).</li>
                  </ul>
                  <li>Powyższe przesłanki stanowią warunek obligatoryjny rozpatrzenia przez Usługodawcę reklamacji.</li>
                  <li>Reklamacje będą rozpatrywane przez Usługodawcę w ciągu 14 dni od ich otrzymania. Decyzja Usługodawcy dotycząca reklamacji będzie przekazywana Usługobiorcy na adres e-mail wskazany w reklamacji lub adres wskazany w korespondencji listownej.</li>
                </ol> 
                <h5>§6 Odstąpienie pouczenie obowiązkowe</h5>
                <ol>
                  <li>Zgodnie z przepisami prawa Klientowi będącemu Konsumentem w myśl art. 27 ustawy z dnia 30 maja 2014 r. (Dz.U. z 2014 r. poz. 827 z późn. zm.) o prawach Konsumenta, przysługuje prawo do odstąpienia od umowy zawartej na odległość bez podawania przyczyny.</li>
                  <li>Prawo do odstąpienia od umowy nie przysługuje Klientowi w odniesieniu do umów określonych w art. 38 ustawy z dnia 30 maja 2014 r. o prawach Konsumenta m. in. w sytuacji:</li>
                  <ol>
                    <li>o świadczenie usług, jeżeli przedsiębiorca wykonał w pełni usługę za wyraźną zgodą Konsumenta, który został poinformowany przed rozpoczęciem świadczenia, że po spełnieniu świadczenia przez przedsiębiorcę utraci prawo odstąpienia od umowy;</li>
                    <li>w której cena lub wynagrodzenie zależy od wahań na rynku finansowym, nad którymi przedsiębiorca nie sprawuje kontroli, i które mogą wystąpić przed upływem terminu do odstąpienia od umowy;</li>
                    <li>w której przedmiotem świadczenia jest rzecz nieprefabrykowana, wyprodukowana według specyfikacji Konsumenta lub służąca zaspokojeniu jego zindywidualizowanych potrzeb</li>
                    <li>w której przedmiotem świadczenia jest rzecz ulegająca szybkiemu zepsuciu lub mająca krótki termin przydatności do użycia</li>
                    <li>w której przedmiotem świadczenia jest rzecz dostarczana w zapieczętowanym opakowaniu, której po otwarciu opakowania nie można zwrócić ze względu na ochronę zdrowia lub ze względów higienicznych, jeżeli opakowanie zostało otwarte po dostarczeniu</li>
                    <li>w której przedmiotem świadczenia są rzeczy, które po dostarczeniu, ze względu na swój charakter zostają nierozłącznie połączone z innymi rzeczami</li>
                    <li>o świadczenie usług hotelarskich, przewozu rzeczy, najmu samochodów, gastronomii, usług związanych z wypoczynkiem, wydarzeniami rozrywkowymi, sportowymi lub kulturalnymi, jeżeli w umowie oznaczono dzień lub okres świadczenia usługi – np. zakup szkolenia stacjonarnego</li>
                  </ol>
                  <li>Z uwagi na fakt, że przedmiotem świadczenia usług są usługi, które są wykonywane niezwłocznie po zawarciu umowy, przed upływem 14- dniowego terminu, do umów zawartych z Usługodawcą w związku z korzystaniem z niniejszego Serwisu, Klientowi  nie przysługuje prawo do odstąpienia od umowy, o czym jest on poinformowany już przed zawarciem umowy.</li>
                </ol>
                <h5>§7 Anulowanie rezerwacji</h5>
                <ol>
                    <li>Klient może anulować lub zmienić rezerwację bez ponoszenia kosztów do 24 godzin przed planowanym przyjazdem.</li>
                    <li>Aby anulować lub zmienić rezerwację Klient musi wysłać wiadomość email z numerem rezerwacji na adres podany w §2 niniejszego Regulaminu na 24h przed planowanym przyjazdem.</li>
                    <li>W przypadku anulowania rezerwacji zwrot płatności jest realizowany w ciągu 14 dni.</li>
                    <li>W przypadku wydłużenia określonego w rezerwacji czasu korzystania z parkingu, zostanie naliczona standardowa opłata obowiązująca na danym parkingu. Klient zobligowany jest do uregulowania kosztów niedopłaty w kasie parkingu.</li>
                </ol>
                <h5>§8 Odpowiedzialność</h5>
                <ol>
                  <li>Usługodawca dołoży wszelkich starań w celu zapewnienia prawidłowego działania Serwisów i jego dostępności przez całą dobę, nie ponosi jednak odpowiedzialności za jakiekolwiek szkody wynikające z nieprawidłowego funkcjonowania Serwisów ze względów technicznych.</li>
                  <li>Serwis internetowy nie odpowiada również za ewentualne szkody urządzeń które korzystają z serwisu, restart urządzenia albo utrata danych na urządzeniu.</li>
                  <li>Usługodawca nie świadczy usług archiwizowania plików, danych lub informacji podawanych przez Usługobiorcę.</li>
                  <li>W przypadku naruszenia Regulaminu przez Usługobiorcę, Usługodawca może zawiesić świadczenie usług bądź rozwiązać ze skutkiem natychmiastowym umowę z Klientem poprzez wyłączenie/usunięcie aktywnych usług. W tej sytuacji Usługobiorcy nie przysługuje prawo do zwrotu żadnej z opłat poniesionych na rzecz Usługodawcy.</li>
                  <li>Usługodawca nie udziela Usługobiorcy jakiejkolwiek gwarancji ani wyraźnej, ani domniemanej, co do efektów i przydatności do określonych zastosowań oferty handlowej oraz jakości i ekonomicznych rezultatów działania oferty.</li>
                  <li>Usługodawca nie ponosi również odpowiedzialności za działania bądź zaniechania  Usługobiorców, ani za nienależyte wykonanie bądź niewykonanie przez nich umów zawartych przez nich z Klientami w szczególności nie ponosi odpowiedzialności za jakość,  bezpieczeństwo, legalność, prawdziwość i rzetelność informacji podawanych przez Usługobiorców. Usługodawca nie ponosi odpowiedzialności za niezawarcie przez Odwiedzających umowy z Usługobiorcami a w szczególności związane z dokonanymi rezerwacjami, zapytaniami czy zawartymi umowami.</li>
                  <li>Usługodawca zastrzega sobie prawo do:</li>
                  <ul>
                    <li>zmiany parametrów i funkcjonalności Kont Usługobiorców;</li>
                    <li>cech użytkowych i możliwości Serwisu, w szczególności zakresu oraz rodzaju Usług i jego funkcjonalności;</li>
                    <li>okresowego wyłączenia Serwisu, związanego w szczególności z jego modyfikacją, konserwacją i naprawą;</li>
                    <li>usunięcia z ważnych powodów całej zawartości serwerów Serwisów lub całkowitego zaprzestania świadczenia Usług, po uprzednim powiadomieniu Użytkowników na stronach Serwisu;</li>
                    <li>zaprzestania świadczenia Usług w ramach Serwisu w stosunku do Usługobiorcy, który narusza postanowienia Regulaminu.</li>
                  </ul>
                </ol> 
                <h5>§9  Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń</h5>
                <ol>
                  <li>Informacje o pozasądowych sposobach rozpatrywania reklamacji i dochodzenia roszczeń, a także zasady dostępu do tych procedur udostępniane są w siedzibach oraz na stronach internetowych powiatowych (miejskich) rzeczników Konsumentów, organizacji społecznych, do których zadań statutowych należy ochrona Konsumentów, Wojewódzkich Inspektoratów Inspekcji Handlowej oraz pod następującymi adresami Urzędu Ochrony Konkurencji i Konsumentów</li>
                  <li>Usługobiorca posiada między innymi następujące możliwości skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia swoich roszczeń:</li>
                  <li>zwrócenie się do Wojewódzkiego Inspektora Inspekcji Handlowej z wnioskiem o wszczęcie postępowania mediacyjnego w sprawie polubownego zakończenia sporu.</li>
                  <li>zwrócenie się do stałego polubownego Sądu Konsumenckiego działającego przy Wojewódzkim Inspektorze Inspekcji Handlowej z wnioskiem o rozstrzygnięcie sporu wynikłego z zawartej umowy, adres www.uokik.gov.pl/wazne_adresy.php</li>
                  <li>zwrócenie się o bezpłatną pomoc prawną m.in. do Federacji Konsumentów - adres strony internetowej: www.federacjakonsumentow.org.pl</li>
                  <li>W rozwiązywaniu sporów transgranicznych pomaga Sieć Europejskich Centrów Konsumenckich. Adresy tych instytucji dostępne są na stronie internetowej Europejskiego Centrum Konsumenckiego www.konsument.gov.pl.</li>
                  <li>Skorzystanie z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń ma charakter dobrowolny i może mieć miejsce tylko i wyłącznie gdy obie strony sporu tj Serwis internetowy i Usługobiorca wyraża na to zgodę.</li>
                </ol>
                <h5>§10  Postanowienia dotyczące  przedsiębiorców</h5>
                <ol>
                  <li>Regulację i postanowienia w niniejszym paragrafie 10 dotyczą tylko i wyłącznie Klientów i Usługobiorców nie będących konsumentami (zakup o charakterze zawodowym).</li>
                  <li>W wypadku klientów będących usługobiorcami i nie będących jednocześnie konsumentami usługodawca może wypowiedzieć umowę o świadczenie Usługi Elektronicznej ze skutkiem natychmiastowym nawet bez wskazywania przyczyn, pod warunkiem że wysłał klientowi  stosowne oświadczenie.</li>
                  <li>Serwis internetowy informuje, że zgodnie z art. 558 § 1 Kodeksu Cywilnego odpowiedzialność z tytułu rękojmi za produkt/usługę wobec klienta nie będącego konsumentem zostaje wyłączona.</li>
                  <li>Odpowiedzialność Serwisu internetowego jest ograniczona w ramach pojedynczego roszczenia, jak i za wszystkie roszczenia w sumie, do wysokości 100 zł netto. Serwis internetowy ponosi odpowiedzialność tylko za typowe szkody przewidywalne w momencie zawarcia umowy i nie ponosi odpowiedzialności z tytułu utraconych korzyści.</li>
                  <li>Wszelkie spory między Serwisem internetowym a Usługobiorcą niebędącym konsumentem zostają poddane sądowi właściwemu ze względu na siedzibę Serwisu internetowego.</li>
                </ol>
                <h5>§11 Metody płatności</h5>
                <ol>
                  <li>Wykorzystanie Serwisu przez Klienta i dokonywanie za jego pośrednictwem rezerwacji lub zakupu Usług parkingowych jest nieodpłatne. Klient jest jednak zobowiązany do opłacenia wynagrodzenia za świadczone Usługi parkingowe na rzecz podmiotu zarządzającego danym Parkingiem.</li>
                  <li>Koszt indywidualnych Usług parkingowych jest widoczny przy poszczególnych ofertach dostępnych w Serwisie.</li>
                  <li>Klient, podczas dokonywania rezerwacji Usług parkingowych za pomocą Serwisu, ma możliwość uregulowania opłaty za Usługi parkingowe na dwa sposoby:</li>
                  <ul>
                    <li>bezpośrednio na terenie parkingu (gotówką lub kartą)</li>
                    <li>online, podczas wypełniania formularza rezerwacyjnego w Serwisie</li>
                  </ul>
                  <li>Transakcje kartą kredytową i e-przelewem są rozliczane przy użyciu platformy PayU lub PayNow.</li>
                </ol>
                <h5>§12 Postanowienia końcowe</h5>
                <ol>
                  <li>Serwis internetowy honoruje wszelkie prawa Użytkowników przewidziane w przepisach obowiązującego prawa. </li>
                  <li>Jeśli obowiązujące prawo przyznaje Klientom będącym konsumentami korzystniejsze obowiązkowe i wymagane prawem uregulowania niż te, które są zawarte w niniejszym Regulaminie, odpowiednie postanowienia Regulaminu są bezpośrednio zastępowane przez konkretne normy obowiązującego prawa i tym samym są wiążące dla w/w właściciela.</li>
                  <li>Wszelkie treści zamieszczone na stronie Serwisu internetowego (wliczając w to grafikę, teksty, układ stron i logotypy) korzystają z ochrony przewidzianej dla praw autorskich i są wyłączną własnością Serwisu. Wykorzystywanie tych treści bez pisemnej zgody Serwisu skutkuje odpowiedzialnością cywilną oraz karną.</li>
                  <li>Oświadczamy że nabyliśmy wszelkie prawa do wszystkich zawartości zamieszczonych na niniejszym Serwisie internetowym oraz zamieszczonych na każdym dołączonym do serwisie portalu i każdej strony bądź podstrony. Jesteśmy właścicielami wszelkich treści, zawartości i materiałów na nich zamieszczonych a w szczególności:</li>
                  <ul>
                    <li>Prawo do korzystania oraz rozporządzania, w najszerszym zakresie dopuszczalnym przez prawo, oraz nabyliśmy wszelkie prawa do dzieł naszych podwykonawców i dalszych podwykonawców, na wszystkich polach eksploatacji.</li>
                    <li>Zostały na nas przeniesione prawa do wykonywania zależnych praw autorskich, do opracowań pracy oraz do wyłącznego zezwalania na wykonywanie zależnego prawa autorskiego, w tym korzystania i rozporządzania pracami. Podwykonawcy i dalsi podwykonawcy ponadto oświadczyli, że</li>
                    <ul>
                      <li>przysługuje nam prawo do utworu wyłączne i nieograniczone prawo autorskie (osobiste i majątkowe);</li>
                      <li>możemy rozporządzać prawami autorskimi do utworu w zakresie niezbędnym do zawarcia i wykonywania zawartej wcześniej umowy;</li>
                      <li>przysługujące nam majątkowe prawa autorskie do utworu i nie zostały zajęte w rozumieniu przepisów o postępowaniu egzekucyjnym;</li>
                      <li>utwory został przez nich wykonane osobiście;</li>
                      <li>utwory nie są opracowaniem, przeróbką lub adaptacją cudzego utworu; </li>
                      <li>możemy posługiwać się tym utworem bez obaw na ewentualne roszczenia osób trzecich.</li>
                    </ul>
                  </ul>
                  <li>Naruszenie naszych praw autorskich będzie surowo karane.</li>
                  <li>Właściciel Serwisu, jako administrator danych osobowych, informują Pana/ Panią, iż:</li>
                  <ul>
                    <li>podanie danych jest zawsze dobrowolne ale niezbędne do realizacji zamówienia;</li>
                    <li>osoba podająca swoje dane osobowe ma nieograniczone prawo dostępu do wszystkich treści swoich danych i ich sprostowania, usunięcia (prawo do bycia zapomnianym), ograniczenia przetwarzania, prawo do przenoszenia danych, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, dane mogą być udostępnione jednak właściwym organom państwa w sytuacji gdy odpowiedni przepis tego będzie wymagał.</li>
                    <li>Podstawą przetwarzania danych osobowych będzie art. 6 ust. 1 pkt a) oraz treść ogólnego rozporządzenia o ochronie danych.;</li>
                    <li>dane osobowe będą przechowywane i przetwarzane przez okres niezbędny do zakończenia przetwarzania i realizacji zamówienia lecz nie dłużej niż przez okres 3 lat (2 lata okres to okres reklamacji i 1 rok na ewentualnie inne roszczenia i sytuację wyjątkowe)</li>
                    <li>osoba udostępniająca swoje dane osobowe ma prawo wniesienia skargi do UODO gdy uzna że przetwarzanie danych osobowych dotyczących realizacji zamówienia narusza przepisy RODO;”</li>
                  </ul>
                  <li>W zakresie przetwarzania danych osobowych niniejszego serwisu nie został stwierdzony przez Komisję Europejską odpowiedni stopień ochrony w drodze decyzji ale dane będą odpowiednio zabezpieczone za pomocą rozwiązań i środków informatyczno/prawnych.</li>
                  <li>Zmieniony Regulamin wiąże Użytkowników jeżeli zostały zachowane wymagania określone w art. 384 Kodeksu cywilnego (tj. Użytkownik został prawidłowo powiadomiony o zmianach).</li>
                  <li>Serwis internetowy zastrzega sobie prawo do dokonywania zmian Regulaminu z ważnych przyczyn to jest:</li>
                  <ul>
                    <li>zmiany przepisów prawa;</li>
                    <li>zmiany sposobu świadczenia usług drogą elektroniczną objętym regulaminem,</li>
                    <li>zmiany danych Serwisu, w tym adresu poczty elektronicznej, numeru telefonu.</li>
                  </ul>
                  <li>Zmiany regulaminu nie mają wpływu na Usługi już realizowane bądź  zrealizowane, do nich zastosowanie ma regulamin obowiązującym w chwili przystąpienia do Usług Serwisu internetowego. Serwis internetowy o zamierzonej zmianie informuje na stronie co najmniej na 30 dni wcześniej. W przypadku braku akceptacji zmienionego regulaminu Usługobiorcy mogą w terminie 30 dni od dnia otrzymania wiadomości wypowiedzieć umowę ze skutkiem natychmiastowym.</li>
                  <li>Spory powstałe w wyniku świadczenia usług na podstawie niniejszego Regulaminu zostaną poddane pod rozstrzygnięcie Sądowi Powszechnemu według wyboru Usługobiorcy będącego jednocześnie konsumentem, zgodnie z właściwymi przepisami prawa Polskiego.</li>
                  <li>Załączniki do Regulaminu stanowią jego integralną część.</li>
                  <li>Usługobiorcy w/w Serwisu mogą uzyskać dostęp do niniejszego Regulaminu w każdym czasie za pośrednictwem odsyłacza zamieszczonego na stronie głównej serwisu oraz pobrać go i sporządzić jego wydruk, komercyjne wykorzystanie podlega jednak ochronie Kancelarii Prawnej LEGATO</li>
                  <li>Regulamin wchodzi w życie z dniem 26.06.2023</li>
                </ol>
                <br>
                <h6>Załaczniki</h6>
                <a href="assets/doc/FORMULARZ ODSTĄPIENIA OD UMOWY.doc.pdf" download="LAF_FORMULARZ_ODSTĄPIENIA_OD_UMOWY.pdf">Pobierz formularz odstąpeinia od umowy</a>
                <br>
                <a href="assets/doc/FORMULARZ ZGŁOSZENIA REKLAMACJI.doc.pdf" download="LAF_FORMULARZ_ZGŁOSZENIA_REKLAMACJI.pdf">Pobierz formularz zgłoszenia reklamacji</a>  
            </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Documentation End -->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->