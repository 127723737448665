import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-sticky-panel-cookies',
  templateUrl: './sticky-panel-cookies.component.html',
  styleUrls: ['./sticky-panel-cookies.component.css']
})
export class StickyPanelCookiesComponent implements OnInit {
  panelClicked = false;

  constructor(private cookieService: CookieService) {
    this.panelClicked = this.cookieService.get('panelClicked') === 'true';
    this.panelClicked =!this.panelClicked;
   }

  ngOnInit(): void {
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }
  handleScroll() {

  }
  showPanel = true;
  togglePanel() {
    this.cookieService.set('panelClicked', 'true', 365);
    this.panelClicked =!this.panelClicked;
  }

}
