<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title">  O nas  </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">LeaveAndFly</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">DOCS</a></li>
                <li class="breadcrumb-item active" aria-current="page">O nas</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- About Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center">

      <!--end col-->

      <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4">Nasza Wizja</h4>
          <p class="text-muted">Jesteśmy innowacyjną platformą online, która umożliwia podróżnym znalezienie i porównanie najlepszych opcji parkingowych na lotniskach. Nasza misja polega na zapewnieniu wygodnego, bezpiecznego i oszczędnego parkowania samochodu dla naszych klientów.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Nasze cechy</h4>
          <p class="text-muted para-desc mx-auto mb-0">Rozpocznij zarządzanie stroną z <span
              class="text-primary fw-bold">LeaveAndFly</span> może zapewnić wszystko, czego potrzebujesz, aby wygenerować najlepsze zyski, ruch i zmniejszyć koszty.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-features></app-features>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- About End -->

<!-- Team Start -->
<section class="section bg-light">
  <div class="container">
    <div class="container mt-60">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title">
            <h4 class="title mb-4">Posiadasz parking i chcesz rozwijać się?</h4>
            <p class="text-muted para-desc mx-auto">Oferujemy autorski program do zarządzania twoim prarkigiem. Dołacz do <span
                class="text-primary fw-bold">Leave and fly</span> i rozwjaj się z nami</p>

            <div class="mt-4">
              <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2">Zarejestruj swój parking</a>
              <a href="/kontakt" class="btn btn-outline-primary mt-2">Kontakt</a>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->

    <!--end section-->
    <!-- Team End -->

    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
      [ngxScrollTo]="'#home'">
      <i-feather name="arrow-up" class="icons"></i-feather>
    </a>
    <!-- Back to top -->