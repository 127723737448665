import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RequestAccessModel } from '../../Model/AccessModel/RequestAccessModel';
import { AccessModel } from '../../Model/AccessModel/AccessModel';


@Injectable({
  providedIn: 'root'
})
export class AccesService {

  constructor(private http: HttpClient) {}

  conetingString:string = environment.api_url + "Form/FormLAFClaient";

  getAccess(requestAccessModel:RequestAccessModel ) : Observable<AccessModel> {
    requestAccessModel.Guid = "d2063323-30a5-46de-ac6a-9d1f177dc05b" ;
    requestAccessModel.Origin = window.location.origin;
    return this.http.post<AccessModel>(this.conetingString,requestAccessModel)
  }
}
